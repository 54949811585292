export enum WebBookingUnavailabilityReason {
    Unknown = 0,
    MaxConcurrentBookingsExceeded = 1,
    MaxConcurrentGuestsExceeded = 2,
    IsClosed = 3,
    ResourcesAreBooked = 4,
    LockTimeHasPassed = 5,
    NoBookableResources = 6,
}

export default WebBookingUnavailabilityReason;
