import { useDisclosure } from "caspeco-casper-ui";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

export enum WebBookingErrorCode {
    Undefined = 0,
    FullyBooked = 1,
    TemporaryUnavailable = 2,
}
interface ErrorContextType {
    error: any;
    setError: Dispatch<SetStateAction<unknown>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    errorCode: WebBookingErrorCode;
    setErrorCode: Dispatch<SetStateAction<WebBookingErrorCode>>;
    openReservationFailedModal: () => void;
    closeReservationFailedModal: () => void;
    isOpenReservationFailedModal: boolean;
}

interface ErrorProviderProps {
    children: ReactNode;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider = ({ children }: ErrorProviderProps) => {
    const [error, setError] = useState<any>(null);
    const [errorCode, setErrorCode] = useState(WebBookingErrorCode.Undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        onOpen: openReservationFailedModal,
        onClose: closeReservationFailedModal,
        isOpen: isOpenReservationFailedModal,
    } = useDisclosure();

    return (
        <>
            <ErrorContext.Provider
                value={{
                    error,
                    setError,
                    isLoading,
                    setIsLoading,
                    errorCode,
                    setErrorCode,
                    openReservationFailedModal,
                    closeReservationFailedModal,
                    isOpenReservationFailedModal,
                }}
            >
                {children}
            </ErrorContext.Provider>
        </>
    );
};

export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error("useError must be used within an ErrorProvider");
    }
    return context;
};
