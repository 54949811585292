import Action from "./actions/action";

import { ILocale, MessageBlob, TranslationEntry } from "old/models/translation";

export class Change extends Action {
    static actionType: string = "locale_change";
    messages?: MessageBlob;
    locale: ILocale;
    part?: string;

    constructor(locale: ILocale, messages?: MessageBlob, part?: string) {
        super(false);
        this.messages = messages;
        this.locale = locale;
        this.part = part;
        this.doDispatch();
    }
}

export class LoadingLocaleMessages extends Action {
    static actionType: string = "Loading_Locale_Messages";
    locale: ILocale;
    part?: string;

    constructor(locale: ILocale, part?: string) {
        super(false);
        this.locale = locale;
        this.part = part;
        this.doDispatch();
    }
}

export class LocaleMessagesFetched extends Action {
    static actionType: string = "locale_messages_fetched";
    messages: MessageBlob;
    locale: ILocale;
    part?: string;

    constructor(locale: ILocale, messages: MessageBlob, part?: string) {
        super(false);
        this.messages = messages;
        this.locale = locale;
        this.part = part;
        this.doDispatch();
    }
}

export class AddLocaleOverrides extends Action {
    static actionType: string = "locale_AddLocaleOverrides";

    constructor(public messages: TranslationEntry) {
        super(false);
        this.doDispatch();
    }
}
