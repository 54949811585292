import { List } from "immutable";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IContactFilterData {
    contactFilterId: number;
    selectedStringItems: List<string>;
}

const ContactFilterDataRecord = RecordFactory<IContactFilterData>({
    contactFilterId: 0,
    selectedStringItems: List<string>(),
});

export class ContactFilterData extends ContactFilterDataRecord implements IContactFilterData {
    constructor(values?: Partial<IContactFilterData>) {
        const convertedValues: Partial<IContactFilterData> = {};
        if (values.selectedStringItems) {
            convertedValues.selectedStringItems = List<string>(values.selectedStringItems);
        }

        super({ ...values, ...convertedValues });
    }
}
