import { Result, ok, err } from "neverthrow";

import { RequestBaseError } from "old/framework/modules/requestBase";
export enum HttpVerb {
    get,
    post,
    put,
    delete,
    patch,
}

export async function getJSON<T>(url: string) {
    return new Promise<T>(
        (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
            fetch(url).then(
                async (res) => {
                    resolve(await res.json());
                },
                (error) => {
                    reject(error);
                }
            );
        }
    );
}

/**
 * Converts a promise to a Result that can be either 'Ok' or 'Err'
 * @example
 * const result = promiseToResult(promise)
 * if(result.isErr()) result.value (contains error)
 * if(result.isOk()) result.value (contains value)
 * @param promise Any promise
 */
export const promiseToResult = <T>(promise: Promise<T>): Promise<Result<T, RequestBaseError>> => {
    return promise
        .then((response) => ok<T, RequestBaseError>(response))
        .catch((error: RequestBaseError) => err(error));
};
