import ConditionRuleEnum from "old/models/webRuleConditionRuleEnum";
import ConditionRulePaymentEnum from "old/models/webRuleConditionRulePaymentEnum";
import { WebBookingTableSeats } from "old/models/webBookingTableSeats";
import { WebBookingUnavailability } from "old/models/webBookingUnavailability";
import { RecordFactory } from "old/common/modules/recordFactory";
import { List } from "immutable";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

export interface IWebBookingTimeRuleUnit {
    start: IMoment;
    end: IMoment;
    availableUntil: IMoment;
    availableSeats: List<number>;
    waitListSeats: List<number>; // Borde vara undefined ifall inga väntelist tider finns eller om väntelista regel inte är påslagen
    isWaitList: boolean;
    unavailability: List<WebBookingUnavailability> | null;
    tableSeats: List<WebBookingTableSeats>;
    ruleId: number;
    disabled: boolean;
    hasMenu: boolean;
    requireMenuSelection: boolean;
    requireMenuPayment: boolean;
    payment: ConditionRulePaymentEnum;
    paymentModifier: ConditionRuleEnum;
    paymentAmount: number;
    paymentVAT: number;
    isTextRow: boolean;
    comment: string | null;
    sectionId: number;
    timeZone: string;
    guid: string;
}

const WebBookingTimeRuleUnitRecord = RecordFactory<IWebBookingTimeRuleUnit>({
    start: moment.invalid(),
    end: moment.invalid(),
    availableUntil: moment.invalid(),
    availableSeats: List<number>(),
    waitListSeats: List<number>(),
    isWaitList: false,
    unavailability: null,
    tableSeats: List<WebBookingTableSeats>(),
    ruleId: -1,
    disabled: false,
    hasMenu: false,
    requireMenuSelection: false,
    requireMenuPayment: false,
    payment: ConditionRulePaymentEnum.NoFee,
    paymentModifier: ConditionRuleEnum.NoPayment,
    paymentAmount: 0,
    paymentVAT: 0,
    isTextRow: false,
    comment: null,
    sectionId: -1,
    timeZone: "",
    guid: uuidv4(),
});

export class WebBookingTimeRuleUnit
    extends WebBookingTimeRuleUnitRecord
    implements IWebBookingTimeRuleUnit
{
    constructor(values?: Partial<IWebBookingTimeRuleUnit>) {
        if (values && !(<any>values).set) {
            values.start = moment(values.start, "YYYY-MM-DDTHH:mm:ss");
            values.end = moment(values.end, "YYYY-MM-DDTHH:mm:ss");
            values.availableUntil = moment(values.availableUntil, "YYYY-MM-DDTHH:mm:ss");

            if (values.availableSeats) {
                values.availableSeats = List<number>(values.availableSeats);
            }

            if (values.waitListSeats) {
                values.waitListSeats = List<number>(values.waitListSeats);
            }

            if (values.unavailability) {
                values.unavailability = List(
                    values.unavailability.map((u) => new WebBookingUnavailability(u))
                );
            }

            if (values.tableSeats) {
                values.tableSeats = List(
                    values.tableSeats.map((ts) => new WebBookingTableSeats(ts))
                );
            }
        }

        values.guid = values?.guid || uuidv4();

        super(values);
    }
}
