import * as Actions from "old/actions/externalBookingSettingsActions";
import { Result } from "old/framework/models/result";
import Store from "old/framework/stores/store";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import ExternalBookingSettingsAPI from "old/models/externalBookingSettingsAPI";

interface IExternalBookingSettingsStore {
    getExternalBookingSettingssResult(
        unitId: number,
        system: string
    ): Result<ExternalBookingSettings>;
}

class ExternalBookingSettingsStore extends Store implements IExternalBookingSettingsStore {
    private _externalBookingSettings: ExternalBookingSettings;

    constructor() {
        super();
        this._externalBookingSettings = new ExternalBookingSettings({});

        this.handle(Actions.LoadedSuccessfully.actionType, this._onExternalBookingSettingsFetched);
    }

    private _onExternalBookingSettingsFetched = (action: Actions.LoadedSuccessfully) => {
        this.setStatus(this.Loaded);
        this._externalBookingSettings = action.externalBookingSettings;
        this.emitChange();
    };

    getExternalBookingSettingssResult(
        unitId: number,
        system: string
    ): Result<ExternalBookingSettings> {
        if (this.getStatus() === this.Loaded) {
            return new Result<ExternalBookingSettings>({
                status: this.getStatus(),
                value: this._externalBookingSettings,
            });
        }
        ExternalBookingSettingsAPI.load(unitId, system);
        return new Result<ExternalBookingSettings>({
            status: this.getStatus(),
            value: <ExternalBookingSettings>new ExternalBookingSettings({}),
        });
    }
}

export default new ExternalBookingSettingsStore();
