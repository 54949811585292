import moment from "moment-timezone";

import ConditionRuleEnum, { ConditionRule } from "old/models/webRuleConditionRuleEnum";
import ConditionRulePaymentEnum, {
    ConditionRulePayment,
} from "old/models/webRuleConditionRulePaymentEnum";
import WebRuleTypeEnum from "old/models/webRuleTypeEnum";
import WebRuleUserTypeEnum from "old/models/webRuleUserTypeEnum";
import WebTimeRuleBookingTypeEnum from "old/models/webTimeRuleBookingTypeEnum";

import { RecordFactory } from "old/common/modules/recordFactory";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { formatTime } from "old/modules/timeHelper";

export interface IWebTimeRule {
    affectsRuleId: number;
    bookingConditions: string;
    bookingMarkingIds: string;
    bookingType: WebTimeRuleBookingTypeEnum;
    comment: string | null;
    dayNumber: number;
    didShowUpRuleType: ConditionRulePaymentEnum;
    endTime: number;
    id: number;
    isLoading: boolean;
    maxBookings: number;
    lockTime: number;
    lockPerTime: boolean;
    cancelDisabled: boolean;
    ruleSetId: number;
    maxGroupSize: number | null;
    validityDate: IMoment;
    recoupTime: number;
    minGroupSize: number | null;
    name: string;
    stepLength: number;
    closedTimes: string;
    vat: number;
    sitLength: number;
    userType: WebRuleUserTypeEnum;
    requireMenuPayment: boolean;
    type: WebRuleTypeEnum;
    reservationDays: number;
    ruleCategoryIds: string;
    menuGuid: string;
    requireMenuSelection: boolean;
    sortOrder: number;
    isTextRow: boolean;
    waitListEnabled: boolean;
    startTime: number;
    paymentAmount: number;
    paymentType: ConditionRuleEnum;
    paymentInformation: string;
    maxGuests: number;
    forceDeliveryInfo: boolean;
}

const WebTimeRuleRecord = RecordFactory<IWebTimeRule>({
    id: -1,
    ruleSetId: -1,
    dayNumber: -1,
    name: "",
    startTime: -1,
    endTime: -1,
    stepLength: -1,
    sitLength: -1,
    closedTimes: "",
    recoupTime: -1,
    maxGuests: -1,
    maxBookings: -1,
    lockTime: -1,
    lockPerTime: false,
    cancelDisabled: false,
    bookingConditions: "",
    type: 1,
    validityDate: moment("0001-01-01", "YYYY-MM-DD"),
    affectsRuleId: -1,
    paymentAmount: 0,
    paymentType: ConditionRuleEnum.NoPayment,
    didShowUpRuleType: ConditionRulePaymentEnum.NoFee,
    vat: 0,
    reservationDays: 0,
    userType: 0,
    minGroupSize: null,
    maxGroupSize: null,
    bookingMarkingIds: "",
    sortOrder: 0,
    menuGuid: "",
    requireMenuSelection: false,
    requireMenuPayment: false,
    isTextRow: false,
    waitListEnabled: false,
    comment: null,
    ruleCategoryIds: "",
    bookingType: 0,
    paymentInformation: "",
    isLoading: false,
    forceDeliveryInfo: false,
});

export class WebTimeRule extends WebTimeRuleRecord implements IWebTimeRule {
    constructor(values?: Partial<IWebTimeRule>) {
        if (values && values.validityDate) {
            values.validityDate = moment(values.validityDate, "YYYY-MM-DD");
        }

        super(values);
    }

    validate(dayOpen?: number, dayClose?: number): ValidationResult {
        let validationResult = new ValidationResult();

        if (dayOpen && !this.isTextRow && dayOpen > this.startTime) {
            validationResult = validationResult.addPropertyError(
                "startTime",
                "booking.webRules.validation.startTime",
                {
                    startTime: formatTime(
                        Math.floor(dayOpen),
                        Math.round((dayOpen - Math.floor(dayOpen)) * 60)
                    ),
                }
            );
        }
        const endTime =
            this.endTime === this.startTime
                ? this.endTime
                : this.endTime >= 0 && this.endTime <= 6
                ? this.endTime + 24
                : this.endTime;
        if (dayClose && !this.isTextRow && dayClose < endTime) {
            validationResult = validationResult.addPropertyError(
                "endTime",
                "booking.webRules.validation.endTime",
                {
                    endTime: formatTime(
                        Math.floor(dayClose),
                        Math.round((dayClose - Math.floor(dayClose)) * 60)
                    ),
                }
            );
        }
        if (this.startTime > endTime && !this.isTextRow) {
            validationResult = validationResult.addPropertyError(
                "startTime",
                "booking.webRules.validation.startTimeGraterThanEndTime"
            );
        }

        if (this) {
            this.forEach((_value: any, property: string) => {
                const errorMessage = this.validatePropertyForError(property);

                if (errorMessage !== null) {
                    validationResult = validationResult.addPropertyError(property, errorMessage);
                }
            });

            if (
                this.maxGroupSize &&
                this.minGroupSize &&
                this.maxGroupSize - this.minGroupSize < 0
            ) {
                validationResult = validationResult.addPropertyError(
                    "maxGroupSize",
                    "booking.webRules.validation.maxMinGroupSize"
                );
            }
        }

        return validationResult;
    }

    validatePropertyForError(propName: string): string | null {
        const validationError = `booking.webRules.validation.${  propName}`;
        switch (propName) {
            case "ruleSetId":
                if (this.ruleSetId < 1) {
                    return validationError;
                }
                break;
            case "dayNumber":
                if (this.dayNumber < 0 || this.dayNumber > 6) {
                    return validationError;
                }
                break;
            case "name":
                if (!this.name || this.name.length < 1) {
                    return validationError;
                }
                break;
            case "startTime":
                if (this.startTime < 0 || this.startTime > 27) {
                    return validationError;
                }
                break;
            case "endTime":
                if (this.endTime < 0 || this.endTime > 27) {
                    return validationError;
                }
                break;
            case "stepLength":
                if (this.stepLength < 5) {
                    return validationError;
                }
                break;
            case "sitLength":
                if (this.sitLength < 1) {
                    return validationError;
                }
                break;
            case "recoupTime":
                if (this.recoupTime < 0 || this.recoupTime >= this.sitLength) {
                    return validationError;
                }
                break;
            case "maxGuests":
                if (this.maxGuests < 1) {
                    return validationError;
                }
                break;
            case "maxBookings":
                if (this.maxBookings < 1) {
                    return validationError;
                }
                break;
            case "lockTime":
                if (this.lockTime < 0) {
                    return validationError;
                }
                break;
            //TODO validation for LockPerTime?
            case "bookingConditions":
                if (this.bookingConditions !== null && this.bookingConditions.length > 65535) {
                    return validationError;
                }
                break;
            case "requireMenuPayment":
                if (this.requireMenuPayment) {
                    if (
                        this.didShowUpRuleType === ConditionRulePayment.NoShowFee ||
                        this.didShowUpRuleType === ConditionRulePayment.NoShowFeeReservation
                    ) {
                        return validationError;
                    }
                }
                break;
            //TODO validate type?
            //TODO validate validityDate?
            //TODO validate affetsRuleId? Warning, it is set after validate, cannot validate that here
            case "paymentType":
                if (
                    this.didShowUpRuleType === ConditionRulePayment.NoShowFee ||
                    this.didShowUpRuleType === ConditionRulePayment.PrePayment
                ) {
                    if (this.paymentType === ConditionRule.NoPayment) {
                        return validationError;
                    }
                }
                break;
            case "paymentAmount":
                if (this.paymentAmount < 0) {
                    return validationError;
                }
                if (
                    (this.didShowUpRuleType === ConditionRulePayment.NoShowFee ||
                        this.didShowUpRuleType === ConditionRulePayment.PrePayment) &&
                    this.paymentAmount < 1
                ) {
                    return validationError;
                }
                break;
            //TODO validate paymentType
            case "vat":
                if (this.vat < 0) {
                    return validationError;
                }
                break;
            case "minGroupSize":
                if (this.minGroupSize && this.minGroupSize <= 0) {
                    return validationError;
                }
                break;
            case "maxGroupSize":
                if (this.maxGroupSize && this.maxGroupSize <= 0) {
                    return validationError;
                }
                break;
            case "isTextRow":
                if (this.isTextRow && !(this.startTime > this.endTime)) {
                    return validationError;
                }
                break;
            default:
        }
        return null;
    }
}
