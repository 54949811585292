import WebBookingStepsEnum from "old/models/webBookingStepsEnum";
import WebBookingStepStatusEnum from "old/models/webBookingStepStatusEnum";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingStep {
    step: WebBookingStepsEnum;
    validationResult: ValidationResult;
    status: WebBookingStepStatusEnum;
}

const WebBookingStepRecord = RecordFactory<IWebBookingStep>({
    step: WebBookingStepsEnum.Undefined,
    validationResult: null,
    status: WebBookingStepStatusEnum.Incomplete,
});

export class WebBookingStep extends WebBookingStepRecord implements IWebBookingStep {
    constructor(values?: Partial<IWebBookingStep>) {
        if (values && values.validationResult) {
            values.validationResult = new ValidationResult(values.validationResult);
        }

        super(values);
    }
}
