import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingTableSeats {
    articleId: number;
    availableSeats: number;
    relatedArticles: string;
}

const WebBookingTableSeatsRecord = RecordFactory<IWebBookingTableSeats>({
    articleId: -1,
    availableSeats: -1,
    relatedArticles: "",
});

export class WebBookingTableSeats
    extends WebBookingTableSeatsRecord
    implements IWebBookingTableSeats
{
    constructor(values?: Partial<IWebBookingTableSeats>) {
        super(values);
    }
}
