import { WebBookingContact } from "old/models/webBookingContact";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingFinalizeParameters {
    contact: WebBookingContact;
    customer: WebBookingCustomer;
    disableAllConfirmationMessages: boolean;
    disableEmailConfirmationMessage: boolean;
    disableSmsConfirmationMessage: boolean;
    message: string;
    logMessage: string;
    lang: string;
    invoiceMarking: string;
    wishToBeInvoiced: boolean;
}

const WebBookingFinalizeParametersRecord = RecordFactory<IWebBookingFinalizeParameters>({
    contact: new WebBookingContact(),
    customer: new WebBookingCustomer(),
    disableAllConfirmationMessages: false,
    disableEmailConfirmationMessage: false,
    disableSmsConfirmationMessage: false,
    message: "",
    logMessage: "",
    lang: "",
    invoiceMarking: "",
    wishToBeInvoiced: false,
});

export class WebBookingFinalizeParameters
    extends WebBookingFinalizeParametersRecord
    implements IWebBookingFinalizeParameters
{
    constructor(values?: Partial<IWebBookingFinalizeParameters>) {
        super(values);
    }
}
