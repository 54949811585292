import i18n from "translations/config/i18n";

export enum SupportedLanguage {
    EN = "en-US",
    SV = "sv-SE",
    NB = "nb-NO",
    DA = "da-DK",
    FI = "fi-FI",
    ES = "es-ES",
    ET = "et-EE",
    RU = "ru-RU",
    FR = "fr-BE",
    NL = "nl-BE",
}

/**
 * Helper to get translated name of current used i18n language.
 * @returns translated name of current language.
 * For ex. i18n language is "SV" the return value will be "Svenska".
 */
export const getNameOfCurrentLang = () => {
    switch (i18n.language) {
        case SupportedLanguage.EN:
            return i18n.t("miscEnglish");
        case SupportedLanguage.SV:
            return i18n.t("miscSwedish");
        case SupportedLanguage.NB:
            return i18n.t("miscNorwegian");
        case SupportedLanguage.DA:
            return i18n.t("miscDanish");
        case SupportedLanguage.FI:
            return i18n.t("miscFinnish");
        default:
            return i18n.t("miscEnglish"); // default to English if no match
    }
};

export default getNameOfCurrentLang;
