import { Box, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import Price from "components/price";
import { useWebBooking } from "hooks";
import { t } from "i18next";
import { PaymentEventType } from "types/enums";
import { isWaitList } from "utils/booking";
import { BookingSummaryItem } from "./booking-summary-list-item";

const PaymentInformation = () => {
    const { data: webBooking } = useWebBooking();

    const isWaitListBooking = isWaitList(webBooking);
    const charge = webBooking.paymentEvents?.findLast(
        (x) =>
            x.type === PaymentEventType.WebBookingCharge ||
            x.type === PaymentEventType.PaymentLinkCharge
    );
    const formattedTransactionDate = charge?.transactionDate?.format("YYYY-MM-DD HH:mm");
    return (
        <Box>
            <Text fontWeight="medium">{t("payment.prePayment")}</Text>
            <Stack spacing={ThemeSpaceVariable.XSmall} mt={ThemeSpaceVariable.Small}>
                <BookingSummaryItem label={t("payment.payed")}>
                    <Price
                        amount={webBooking.chargePayedAmount}
                        display="symbol"
                        color={ThemeColorVariable.OnSurfaceSubdued}
                    />
                </BookingSummaryItem>
                {!isWaitListBooking && (
                    <BookingSummaryItem label="" value={formattedTransactionDate} />
                )}
            </Stack>
        </Box>
    );
};

export default PaymentInformation;
