import { RecordFactory } from "old/common/modules/recordFactory";

export interface IMandatoryCustomerFields {
    city: boolean;
    customerFilter: boolean;
    customerNumber: boolean;
    email: boolean;
    fax: boolean;
    information: boolean;
    invoiceStreetaddress: boolean;
    marking: boolean;
    orgNr: boolean;
    phone: boolean;
    streetaddress: boolean;
    webAddress: boolean;
    zipcode: boolean;
}

const MandatoryCustomerFieldsRecord = RecordFactory<IMandatoryCustomerFields>({
    customerNumber: false,
    orgNr: false,
    streetaddress: false,
    zipcode: false,
    city: false,
    phone: false,
    email: false,
    fax: false,
    webAddress: false,
    information: false,
    marking: false,
    invoiceStreetaddress: false,
    customerFilter: false,
});

export class MandatoryCustomerFields
    extends MandatoryCustomerFieldsRecord
    implements IMandatoryCustomerFields
{
    constructor(values?: Partial<IMandatoryCustomerFields>) {
        super(values);
    }
}
