import { WebBookingContact } from "old/models/webBookingContact";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingContactCustomerConnection {
    customerFriendlyId: string;
    contact: WebBookingContact | null;
    customer: WebBookingCustomer | null;
}

const WebBookingContactCustomerConnectionRecord =
    RecordFactory<IWebBookingContactCustomerConnection>({
        customerFriendlyId: "",
        contact: null,
        customer: null,
    });

export class WebBookingContactCustomerConnection
    extends WebBookingContactCustomerConnectionRecord
    implements IWebBookingContactCustomerConnection
{
    constructor(values: Partial<IWebBookingContactCustomerConnection> = {}) {
        super(values);
    }
}
