import IAPISettings from "old/framework/models/IAPISettings";

import { EStorageType, StorageManager } from "old/application/storageManager/storageManager";
import {
    DeleteRequest,
    GetRequest,
    IOptions,
    PostRequest,
    PutRequest,
    RequestBase,
} from "./requestBase";
import { BASE_URL } from "utils";

type IGetOptions = Except<IOptions, "data">;

// Used by auto tests
(<any>window).openRequestCount = 0;

interface IAPIErrorData extends Object {
    message: string;
    original?: any;
    method?: "PUT" | "GET" | "DELETE" | "POST" | "OPTION";
    stack: string;
    status: number;
    url?: string;
    response: Response;
}

export interface IAPIError {
    response: Response;
    error: IAPIErrorData;
    operation?: any;
}

export interface IAPIOperation {
    method?: Function;
    payload?: any;
    fromEvent?: boolean;
    url?: string;
}

export class UnauthedAPIBase {
    private _settings: IAPISettings;
    private _apiVersion: string;
    private readonly _sM: StorageManager;
    private _ongoingAsyncRequests: { [key: string]: Promise<any> | undefined } = {};

    private _apiUrl: string;
    //private _defaultApiUrl: string;

    constructor() {
        this._sM = StorageManager.GetInstance();
        this._updateSettings();
    }

    private _updateSettings() {
        this._apiUrl = BASE_URL;

        if (document.location.href.indexOf("wallaby_sandbox") > -1) {
            this._apiUrl = "https://localhost:9090/";
        }

        if (
            document.location.href.indexOf("webplatform-trackingdev.azure.caspeco.com") > -1 ||
            document.location.href.indexOf("webplatform-trackingdev.azurewebsites.net") > -1
        ) {
            this._apiUrl = "//marc-trackingdev-as-ne.azure.caspeco.com/";
        }

        // remember the defaultApiUrl before getting a custom one
        //this._defaultApiUrl = this._apiUrl;
        this._apiUrl =
            this._sM.retrieve("api.settings.url", EStorageType.LocalStorage, true) || this._apiUrl;

        // this._apiUrl = "https://localhost:9090/";

        this._settings = {
            authorizationToken: "",
            tokenValidUntil: null,
            apiUrl: this._apiUrl,
            apiVersion: "v1",
            applicationName: "Mobile",
            defaultAccepts: "application/json",
            defaultContentType: "application/json",
            systemName: "",
            connectionId: "",
        };
    }

    setApiVersion(version: string) {
        this._apiVersion = version;
    }

    getSettings() {
        if (this._settings.systemName === "") {
            this._updateSettings();
        }
        // override default settings
        if (this._apiVersion !== null) {
            this._settings.apiVersion = this._apiVersion;
        }

        return this._settings;
    }

    get<T = any>(endpoint: string, options: IOptions, settings?: IAPISettings) {
        if (settings !== undefined) options.skipAuth = false;
        const req = new GetRequest<T>(endpoint, options, { ...this.getSettings(), ...settings });
        return this.fire(req);
    }

    getAsync<T = any, R = any>(
        endpoint: string,
        mapper: (data: R) => T,
        options: IGetOptions
    ): Promise<T> {
        return new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.get<R>(endpoint, options)
                .then((result) => {
                    const data = result.data;
                    if (options && options.abortKey)
                        this._ongoingAsyncRequests[options.abortKey] = undefined;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    if (options && options.abortKey)
                        this._ongoingAsyncRequests[options.abortKey] = undefined;
                    reject(errorObj);
                });
        });
    }

    post<T = any>(endpoint: string, options: IOptions, settings?: IAPISettings) {
        if (settings !== undefined) options.skipAuth = false;
        const req = new PostRequest<T>(endpoint, options, { ...this.getSettings(), ...settings });
        return this.fire(req);
    }

    postAsync<T = any, R = any>(
        endpoint: string,
        mapper: (data: R) => T,
        options: IOptions
    ): Promise<T> {
        return new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.post<R>(endpoint, options)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
    }

    put<T = any>(endpoint: string, options?: IOptions) {
        const req = new PutRequest<T>(endpoint, options, this.getSettings());
        return this.fire(req);
    }

    putAsync<T = any, R = any>(
        endpoint: string,
        mapper: (data: R) => T,
        options?: IOptions
    ): Promise<T> {
        return new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.put<R>(endpoint, options)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
    }

    delete<T = any>(endpoint: string, options?: IOptions) {
        const req = new DeleteRequest<T>(endpoint, options, this.getSettings());
        return this.fire(req);
    }

    deleteAsync<T = any, R = any>(
        endpoint: string,
        mapper: (data: R) => T,
        options?: IOptions
    ): Promise<T> {
        return new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.delete<R>(endpoint, options)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
    }

    fire<T>(req: RequestBase<T>) {
        if (req.options.skipAuth === undefined) req.options.skipAuth = true;
        //if (!req.options.headers.System || req.options.headers.System.length < 1) throw new Error("UnauthedAPI requires that a System is specified.");
        return req.fire(null);
    }
}
