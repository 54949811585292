import * as AppActions from "old/framework/actions/applicationActions";

import AppDispatcher from "old/framework/appDispatcher";
import ev from "../eventEmitter";
import IStore from "../istore";

import { LoadingStatus } from "old/framework/models/loadingStatusEnum";

export class Store<T = any, D = any> extends ev implements IStore {
    private _bindings: { [actionType: string]: (action: any) => void } = {};
    private _pendingOperations: { [key: string]: string | undefined } = {};
    private _internalStatuses: { [key: string]: LoadingStatus } = {};
    protected _internalState?: T;

    public readonly NotLoaded: LoadingStatus = LoadingStatus.NotLoaded;
    public readonly Loading: LoadingStatus = LoadingStatus.Loading;
    public readonly Loaded: LoadingStatus = LoadingStatus.Loaded;
    public readonly Error: LoadingStatus = LoadingStatus.Error;

    constructor(initialState?: T) {
        super();

        this._internalState = initialState;

        this.handle(AppActions.Reload.actionType, this._handleReloadBase);
    }

    handle<TActionBinding>(actionType: string, handler: (payload: TActionBinding) => void): void {
        this._bindings[actionType] = handler;
    }

    dispatcherIndex = AppDispatcher.register((payload): void => {
        const actionConstructor: any = payload.action.constructor;
        const actionType = actionConstructor?.actionType ?? payload.action.type;
        const handler: (action: any) => void = this._bindings[actionType];

        if (handler) {
            handler(payload.action);
        }
    });

    emitChange = (data?: D) => {
        this.emit("change", data);
    };

    onChange = (callback: (data?: D) => void) => {
        this.on("change", callback);
    };

    offChange = (callback: (data?: D) => void) => {
        this.off("change", callback);
    };

    pushPending(operation: any): string {
        const key = new Date().getTime().toString();
        this._pendingOperations[key] = operation;
        return key;
    }

    popPending(operationId: string): any {
        const operation = this._pendingOperations[operationId];
        this._pendingOperations[operationId] = undefined;
        return operation;
    }

    private _handleReloadBase = (action: AppActions.Reload) => {
        this.resetStatuses();
        this.emitChange();
    };

    getState(): NonNullable<T> {
        return <NonNullable<T>>this._internalState;
    }

    //Removes specific status that starts with the prefix you sent
    removeStatus(prefix: string) {
        Object.keys(this._internalStatuses).forEach((key) => {
            if (key.substring(0, prefix.length) === prefix) {
                delete this._internalStatuses[key];
            }
        });
    }

    resetStatuses(excludeStatuses?: string[]) {
        if (excludeStatuses) {
            const toBeSaved: { [index: string]: LoadingStatus } = {};

            excludeStatuses.forEach(
                (status) => (toBeSaved[status] = this._internalStatuses[status])
            );

            this._internalStatuses = toBeSaved;
        } else {
            this._internalStatuses = {};
        }
    }

    getStatus(statusName?: string): LoadingStatus {
        if (!statusName) {
            statusName = "status";
        }
        if (this._internalStatuses[statusName]) {
            return this._internalStatuses[statusName];
        }
        return this.NotLoaded;
    }

    setStatus(status: LoadingStatus, statusName?: string) {
        if (!statusName) {
            statusName = "status";
        }
        this._internalStatuses[statusName] = status;
    }

    getStatuses(prefix?: string): { [index: string]: LoadingStatus } {
        if (prefix) {
            const result: { [key: string]: LoadingStatus } = {};
            Object.keys(this._internalStatuses).forEach((key) => {
                if (key.substring(0, prefix.length) === prefix) {
                    result[key] = this._internalStatuses[key];
                }
            });

            return result;
        }

        return this._internalStatuses;
    }
}

export default Store;
