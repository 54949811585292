import { numberFormat } from "old/common/mixins/localeHelper";
import { List, OrderedMap } from "immutable";
import { translate } from "old/common/mixins/localeHelper";

export function isBetween(x: number, min: number, max: number): boolean {
    return x >= min && x <= max;
}
export function roundToTwoDecimals(value: number): number {
    return roundToPrecision(value, 2);
}
export const clamp = (v: number, min: number, max: number) => Math.min(Math.max(v, min), max);

export function roundToPrecision(value: number, precision: number): number {
    if (!value.toString().includes("e")) {
        return +`${Math.round(<any>`${value}e+${precision}`)}e-${precision}`;
    }
    const parts = value.toString().split("e");
    const sig = +parts[1] + precision > 0 ? "+" : "";
    return +`${Math.round(<any>`${+parts[0]}e${sig}${+parts[1] + precision}`)}e-${precision}`;
}

export function numberToString(value: number, isInteger: boolean): string {
    if (typeof value !== "number") {
        value = parseFloat(<any>value);
    }

    if (value !== 0 && !isNaN(value)) {
        if (isInteger) {
            return Math.round(value).toString();
        }
        return numberFormat(value);
    }
    return "";
}

export function numberToStringRounded(
    value: number,
    isInteger: boolean,
    precision: number
): string {
    if (typeof value !== "number") {
        value = parseFloat(<any>value);
    }

    if (!isNaN(value)) {
        if (isInteger) {
            return Math.round(value).toString();
        }
        return numberFormat(roundToPrecision(value, precision), 2, 0);
    }
    return "";
}

export function zeroPad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
}

const romanNumbersLookup = OrderedMap<string, number>({
    // @ts-ignore
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
});
export function romanize(decimalNumber: number): string {
    if (decimalNumber < 1) {
        throw new Error(
            "Zero or negative decimal numbers can not be represented by roman numbers."
        );
    } else if (decimalNumber > 4999) {
        throw new Error("Decimal numbers above 4999 can not be represented by roman numbers.");
    }
    let roman = "";
    romanNumbersLookup.forEach((value, key) => {
        while (decimalNumber >= romanNumbersLookup.get(key)) {
            roman += key;
            decimalNumber -= value;
        }
    });
    return roman;
}

export class ordinalNumber {
    public index: number;
    public name: string;
}
