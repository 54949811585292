import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericGet, get, post } from "api/apiMethods";
import { useBookingIdParam } from "hooks";
import { formatTargetLanguage } from "old/framework/modules/setLanguage";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { WaitListOfferStatus } from "old/models/waitListOffer";
import { WebBookingCancelParameters } from "old/models/webBookingCancelParameters";
import { useNavigate } from "react-router-dom";
import i18n from "translations/config/i18n";
import { getUrlParams } from "utils";
import { WebBooking } from "./models/webBooking";

export const webBookingQuery = (id: string, onSuccess?: (data: WebBooking) => void) => ({
    queryKey: ["booking", id],
    queryFn: async () => {
        const response = await genericGet(`webBooking/webBookings/${id}`).then(
            (response: WebBooking) => {
                return response;
            }
        );

        if (onSuccess) {
            onSuccess(response);
        }
        return response;
    },
});

export function useWebBooking() {
    const bookingId = useBookingIdParam();
    // const { setError, setIsLoading } = useError();

    let onSuccess: (data: WebBooking) => void | undefined = undefined;
    const currentState = history.state;
    const isFinal = currentState?.usr?.isFinal;
    if (!isFinal) {
        // Ska query param > bokningens språk?
        onSuccess = (data: WebBooking) => i18n.changeLanguage(formatTargetLanguage(data.language));
    }

    return useQuery({
        ...webBookingQuery(bookingId, onSuccess),
        refetchOnMount: false,
        // The query will not execute unless theres a unitId from the booking and not in the params
        enabled: !!bookingId,
    });
}

let resolveRestaurantName: (value: string) => void;
export const restaurantNamePromise: Promise<string> = new Promise((resolve) => {
    resolveRestaurantName = resolve;
});
const waitForRestaurantName = async (promise: Promise<ExternalBookingSettings>) => {
    const settings = await promise;
    resolveRestaurantName(settings.unitsMetaData?.name);
};

export const externalBookingSettingsQuery = (unitId?: number) => {
    return {
        queryKey: ["externalBookingSettings"],
        queryFn: async () => {
            const promise = get<ExternalBookingSettings>(
                `booking/externalBookingSettings/${unitId}`
            );
            await waitForRestaurantName(promise);
            return promise;
        },
        // The query will not execute until the unitId exists
        enabled: !!unitId,
    };
};

export function useExternalBookingSettings() {
    const unitIdFromUrl = getUrlParams().unitId;
    const webBooking = useWebBooking().data;

    // Use the unitId from the URL if it exists, otherwise use the one from the booking
    const unitId = unitIdFromUrl || webBooking?.unitId;

    const queryResult = useQuery({
        ...externalBookingSettingsQuery(unitId), // Pass setError to externalBookingSettingsQuery
        refetchOnMount: false,
    });

    // Return the data if it exists, otherwise return the uninitialized variant
    return queryResult.data || new ExternalBookingSettings();
}

export function useExternalBookingSettingsQuery() {
    const urlParams = getUrlParams();
    const unitIdFromUrl = urlParams.unitId;
    const webBooking = useWebBooking().data;

    // Use the unitId from the URL if it exists, otherwise use the one from the booking
    const unitId = unitIdFromUrl || webBooking?.unitId;

    const queryResult = useQuery({
        ...externalBookingSettingsQuery(unitId),
        refetchOnMount: false,
    });

    // Return the data if it exists, otherwise return the uninitialized variant
    return queryResult;
}

export const useCancelBooking = (id: string) => {
    const postCancelBooking = () =>
        post<WebBooking, WebBookingCancelParameters>(`WebBooking/WebBookings/${id}/Cancel`, {
            disableAllConfirmationMessages: false,
            disableEmailConfirmationMessage: false,
            disableSmsConfirmationMessage: false,
            logMessage: "",
        } as WebBookingCancelParameters);

    return useMutation({
        mutationFn: postCancelBooking,
    });
};

interface IWaitListConfirmParameters {
    startTime: string;
    disableAllConfirmationMessages: boolean;
    disableEmailConfirmationMessage: boolean;
    disableSmsConfirmationMessage: boolean;
}

export const useConfirmWaitListBooking = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: booking } = useWebBooking();
    const { guid: id } = booking;

    const postConfirmBooking = () =>
        post<WebBooking, IWaitListConfirmParameters>(
            `WebBooking/WebBookings/${id}/AcceptWaitListOffer`,
            {
                startTime: booking?.waitListOffers
                    ?.find((offer) => offer.offerStatus === WaitListOfferStatus.Available)
                    ?.startTime.format("YYYY-MM-DD[T]HH:mm:ss"),
                disableAllConfirmationMessages: false,
                disableEmailConfirmationMessage: false,
                disableSmsConfirmationMessage: false,
            }
        );

    return useMutation({
        mutationFn: postConfirmBooking,
        onSuccess: (data) => {
            queryClient.setQueryData<WebBooking>(["booking", id], () => {
                return data;
            });

            navigate(location.pathname, {
                replace: true,
                state: { isFinal: true },
            });
        },
        onError: (error) => {
            console.error("Error confirming waitlist booking", error);
            queryClient.setQueryData<WebBooking>(["booking", id], (currentData) => {
                currentData.waitListOffers = currentData.waitListOffers.map((offer) =>
                    offer.set("offerStatus", WaitListOfferStatus.Unavailable)
                );

                return currentData;
            });
        },
    });
};
