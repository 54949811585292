import { GeneralMessage } from "old/models/generalMessage";
import { PropertyError } from "old/models/propertyError";
import { PropertyWarning } from "old/models/propertyWarning";
import { IStaticallyTypedRecord, RecordFactory } from "old/common/modules/recordFactory";
import { List, Set } from "immutable";

export interface IValidationResultValues {
    generalErrors: List<GeneralMessage>;
    generalWarnings: List<GeneralMessage>;
    technicalErrors: List<GeneralMessage>;
    propertyErrors: List<PropertyError>;
    propertyWarnings: List<PropertyWarning>;
    propertyInfo: List<PropertyWarning>;
}

const ValidationResultRecord = RecordFactory<IValidationResultValues>({
    generalErrors: List<GeneralMessage>(),
    generalWarnings: List<GeneralMessage>(),
    technicalErrors: List<GeneralMessage>(),
    propertyErrors: List<PropertyError>(),
    propertyWarnings: List<PropertyWarning>(),
    propertyInfo: List<PropertyWarning>(),
});

export class ValidationResult extends ValidationResultRecord implements IValidationResultValues {
    constructor(values?: Partial<IValidationResultValues>) {
        const convertedValues: Partial<IValidationResultValues> = {};
        if (values) {
            if (values.generalErrors) {
                convertedValues.generalErrors = List(values.generalErrors);
            }

            if (values.generalWarnings) {
                convertedValues.generalWarnings = List(values.generalWarnings);
            }

            if (values.technicalErrors) {
                convertedValues.technicalErrors = List(values.technicalErrors);
            }

            if (values.propertyErrors) {
                convertedValues.propertyErrors = List(values.propertyErrors);
            }

            if (values.propertyWarnings) {
                convertedValues.propertyWarnings = List(values.propertyWarnings);
            }

            if (values.propertyInfo) {
                convertedValues.propertyInfo = List(values.propertyInfo);
            }
        }
        super({ ...values, ...convertedValues });
    }

    public static validate<T extends Object>(
        item: IStaticallyTypedRecord<T>,
        validatePropertyForError: (
            item: IStaticallyTypedRecord<T>,
            property: keyof T
        ) => string | null
    ): ValidationResult {
        let validationResult = new ValidationResult();
        if (item) {
            item.forEach((_value: any, property: keyof T) => {
                const errorMessage = validatePropertyForError(item, property);
                if (errorMessage !== null) {
                    validationResult = validationResult.addPropertyError(property, errorMessage);
                }
            });
        }
        return validationResult;
    }

    public static validateForWarning<T extends Object>(
        item: IStaticallyTypedRecord<T>,
        validatePropertyForWarning: (
            item: IStaticallyTypedRecord<T>,
            property: keyof T
        ) => string | null
    ): ValidationResult {
        let validationResult = new ValidationResult();
        if (item) {
            item.forEach((_value: any, property: keyof T) => {
                const errorMessage = validatePropertyForWarning(item, property);
                if (errorMessage !== null) {
                    validationResult = validationResult.addPropertyWarning(property, errorMessage);
                }
            });
        }
        return validationResult;
    }

    isValid(): boolean {
        return (
            (!this.generalErrors || this.generalErrors.isEmpty()) &&
            (!this.propertyErrors || this.propertyErrors.isEmpty()) &&
            (!this.technicalErrors || this.technicalErrors.isEmpty())
        );
    }

    hasInfo(): boolean {
        return !this.propertyInfo.isEmpty();
    }

    hasWarning(): boolean {
        return !(this.generalWarnings.isEmpty() && this.propertyWarnings.isEmpty());
    }

    addPropertyError<T = any>(
        propertyName: keyof T,
        message: string,
        messageData?: Record<string, any>,
        dependsOnProperties?: string[],
        noDuplicates: boolean = false
    ): ValidationResult {
        if (noDuplicates && this.propertyErrors.some((w) => w.message === message)) {
            return this;
        }
        return this.set(
            "propertyErrors",
            this.propertyErrors.push(
                new PropertyError({
                    propertyName: propertyName.toString(),
                    message,
                    messageData,
                    dependsOnProperties: Set(dependsOnProperties ?? []),
                })
            )
        );
    }

    addTechnicalError(
        message: string,
        messageData?: Record<string, any>,
        noDuplicates: boolean = false
    ): ValidationResult {
        if (noDuplicates && this.technicalErrors.some((w) => w.message === message)) {
            return this;
        }

        return this.set(
            "technicalErrors",
            this.technicalErrors.push(new GeneralMessage({ message, messageData }))
        );
    }

    addGeneralError(
        message: string,
        messageData?: Record<string, any>,
        noDuplicates: boolean = false
    ): ValidationResult {
        if (noDuplicates && this.generalErrors.some((w) => w.message === message)) {
            return this;
        }
        return this.set(
            "generalErrors",
            this.generalErrors.push(new GeneralMessage({ message, messageData }))
        );
    }

    addPropertyWarning<T = any>(
        propertyName: keyof T,
        message: string,
        messageData?: Record<string, any>
    ): ValidationResult {
        return this.set(
            "propertyWarnings",
            this.propertyWarnings.push(
                new PropertyWarning({ propertyName: propertyName.toString(), message, messageData })
            )
        );
    }

    addPropertyInfo(propertyName: string, message: string, messageData?: Record<string, any>) {
        return this.set(
            "propertyInfo",
            this.propertyInfo.push(new PropertyWarning({ propertyName, message, messageData }))
        );
    }

    addValidationResult(validationResult: ValidationResult | null) {
        if (!validationResult) return this;
        return this.withMutations((mutable) => {
            mutable.set(
                "generalErrors",
                this.generalErrors.concat(validationResult.generalErrors).toList()
            );
            mutable.set(
                "generalWarnings",
                this.generalWarnings.concat(validationResult.generalWarnings).toList()
            );
            mutable.set(
                "propertyErrors",
                this.propertyErrors.concat(validationResult.propertyErrors).toList()
            );
            mutable.set(
                "propertyWarnings",
                this.propertyWarnings.concat(validationResult.propertyWarnings).toList()
            );
            mutable.set(
                "propertyInfo",
                this.propertyInfo.concat(validationResult.propertyInfo).toList()
            );
            return mutable;
        });
    }

    addGeneralWarning(
        message: string,
        messageData?: Record<string, any>,
        noDuplicates: boolean = false
    ): ValidationResult {
        if (noDuplicates && this.generalWarnings.some((w) => w.message === message)) {
            return this;
        }

        return this.set(
            "generalWarnings",
            this.generalWarnings.push(new GeneralMessage({ message, messageData }))
        );
    }

    removeGeneralWarning(message: string): ValidationResult {
        return this.set(
            "generalWarnings",
            this.generalWarnings.filter((m) => m.message !== message).toList()
        );
    }

    removeGeneralError(message: string): ValidationResult {
        return this.set(
            "generalErrors",
            this.generalWarnings.filter((m) => m.message !== message).toList()
        );
    }

    addCombinedPropertyError(
        propertyNames: string[],
        message: string,
        messageData?: Record<string, any>
    ) {
        let tempValidationResult: ValidationResult | undefined = undefined;

        propertyNames.forEach((propertyName) => {
            tempValidationResult = (tempValidationResult ?? this).addPropertyError(
                propertyName,
                message,
                messageData,
                propertyNames
            );
        });

        return tempValidationResult ?? this;
    }

    mergeValidationResult(validationResult2: ValidationResult) {
        return this.withMutations((mutable) => {
            mutable.set(
                "generalErrors",
                this.addUniqueMessage(this.generalErrors, validationResult2.generalErrors)
            );
            mutable.set(
                "generalWarnings",
                this.addUniqueMessage(this.generalWarnings, validationResult2.generalWarnings)
            );
            mutable.set(
                "technicalErrors",
                this.addUniqueMessage(this.technicalErrors, validationResult2.technicalErrors)
            );
            mutable.set(
                "propertyErrors",
                this.addUniqueProperties(this.propertyErrors, validationResult2.propertyErrors)
            );
            mutable.set(
                "propertyWarnings",
                this.addUniqueProperties(this.propertyWarnings, validationResult2.propertyWarnings)
            );
            mutable.set(
                "propertyInfo",
                this.addUniqueProperties(this.propertyInfo, validationResult2.propertyInfo)
            );

            return mutable;
        });
    }

    addUniqueMessage(currentElements: List<GeneralMessage>, newElements: List<GeneralMessage>) {
        if (!currentElements || !newElements) {
            return currentElements ? currentElements : newElements;
        }
        return currentElements
            .concat(newElements.filter((e) => !this.messageAlreadyExists(currentElements, e)))
            .toList();
    }

    messageAlreadyExists(
        currentElements: List<GeneralMessage>,
        newElement: GeneralMessage
    ): boolean {
        return !!currentElements?.some(
            (e) => e.message === newElement.message && e.messageData === newElement.messageData
        );
    }

    addUniqueProperties<T extends PropertyError | PropertyWarning>(
        currentElements: List<T>,
        newElements: List<T>
    ) {
        if (!currentElements || !newElements) {
            return currentElements ? currentElements : newElements;
        }
        return currentElements
            .concat(newElements.filter((e) => !this.propertyAlreadyExists(currentElements, e)))
            .toList();
    }

    propertyAlreadyExists<T extends PropertyError | PropertyWarning>(
        currentElements: List<T>,
        newElement: T
    ): boolean {
        return !!currentElements?.some(
            (e) => e.propertyName === newElement.propertyName && e.message === newElement.message
        );
    }

    removeProperyErrorsAndWarnings(propertyName?: string): ValidationResult {
        if (propertyName) {
            return this.update("propertyErrors", (errors: List<PropertyError>) =>
                errors
                    .filter(
                        (pe) =>
                            pe.propertyName !== propertyName &&
                            (!pe.dependsOnProperties || !pe.dependsOnProperties.has(propertyName))
                    )
                    .toList()
            )
                .update("propertyWarnings", (warnings: List<PropertyWarning>) =>
                    warnings.filter((pw) => pw.propertyName !== propertyName).toList()
                )
                .update("propertyInfo", (warnings: List<PropertyWarning>) =>
                    warnings.filter((pw) => pw.propertyName !== propertyName).toList()
                );
        } 
            return this.set("propertyErrors", List<PropertyError>())
                .set("propertyWarnings", List<PropertyWarning>())
                .set("propertyInfo", List<PropertyWarning>());
        
    }
}
