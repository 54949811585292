import DOMPurify from "dompurify";
import * as React from "react";

interface ISafeHTMLProps<P extends React.HTMLAttributes<T>, T extends HTMLElement> {
    element: keyof React.ReactHTML;
    html: string;
    options?: any; // sanitize.IOptions;
    className?: string;
    props?: React.ClassAttributes<T> & P;
    children?: React.ReactNode | undefined;
}

interface ISafeHTMLState {}

export class SafeHTML<
    P extends React.HTMLAttributes<T>,
    T extends HTMLElement
> extends React.Component<ISafeHTMLProps<P, T>, ISafeHTMLState> {
    render() {
        const sanitizedHtml = DOMPurify.sanitize(this.props.html);

        return React.createElement(this.props.element, {
            dangerouslySetInnerHTML: { __html: sanitizedHtml },
            className: this.props.className,
            ...this.props.props,
        });
    }
}
