import {
    Box,
    Button,
    Heading,
    Icon,
    Icons,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";

interface ErrorTemplateProps {
    btnCallback?: () => void;
    btnText?: string;
    icon: Icons;
    title: string;
    message: string;
}

const ErrorTemplate = ({ btnCallback, btnText, icon, title, message }: ErrorTemplateProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
            mt={ThemeSpaceVariable.Large}
        >
            <Icon icon={icon} color="#242236" fontSize="60px" />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={ThemeSpaceVariable.Small}
            >
                <Heading fontWeight="medium" as="h2">
                    {title}
                </Heading>
                <Text fontSize={ThemeFontSizeVariable.Large}>{message}</Text>
                {btnCallback && (
                    <Button variant="primary" onClick={btnCallback}>
                        {btnText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ErrorTemplate;
