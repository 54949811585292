import { List, Map } from "immutable";
import { loadDayStates } from "old/actions/webBookingActionCreators";
import * as Actions from "old/actions/webBookingActions";
import { Result } from "old/framework/models/result";
import Store from "old/framework/stores/store";
import { DayState } from "old/models/dayState";
import { WebBooking } from "old/models/webBooking";
import WebBookingAPI from "old/models/webBookingAPI";
import { WebBookingPaymentTerminalParameters } from "old/models/webBookingPaymentTerminalParameters";
import { WebBookingUnit } from "old/models/webBookingUnit";

interface IWebBookingStore {
    getWebBookingUnitsResult(
        dates: List<IMoment>,
        unitIds: List<number>,
        sectionIds: List<number>
    ): Result<List<WebBookingUnit>>;
    getWebBookingUnits(): List<WebBookingUnit>;
    getTerminalUrl(
        guid: string,
        parameters: WebBookingPaymentTerminalParameters,
        system: string
    ): string;
    getWebBookingResult(bookingPublicId: string, system: string): Result<WebBooking>;
    getViewedMonthDayStates(): Map<string, List<DayState>>;
    getLoadedMonthDayStateResult(
        moment: IMoment,
        unitId: number,
        sectionIds: List<number>,
        system: string
    ): Result<List<DayState>>;
    flushData(): void;
}

export interface IWebBookingState {
    webBookingUnits: List<WebBookingUnit>;
    webBooking: WebBooking;
    webBookingDayStatesMap: Map<string, List<DayState>>;
    webBookingLoadedMonthDayStates: List<DayState>;
    loadedInitalDayState: boolean;
}

class WebBookingStore extends Store<IWebBookingState> implements IWebBookingStore {
    private _webBookingUnits: List<WebBookingUnit>;
    private _webBooking: WebBooking;
    private _webBookingDayStatesMap: Map<string, List<DayState>>;
    private _webBookingLoadedMonthDayStates: List<DayState>;
    private _loadedInitalDayState: boolean = false;

    constructor() {
        super();
        this._webBookingUnits = List<WebBookingUnit>();
        this._webBooking = new WebBooking({});
        this._webBookingDayStatesMap = Map<string, List<DayState>>();
        this._webBookingLoadedMonthDayStates = List<DayState>();

        this.handle(Actions.LoadingAvailableTimes.actionType, this._onLoadingWebBookingUnits);
        this.handle(
            Actions.LoadingAvailableTimesFailed.actionType,
            this._onLoadingWebBookingUnitsFailed
        );
        this.handle(
            Actions.LoadedAvailableTimesSuccessfully.actionType,
            this._onWebBookingUnitsFetched
        );
        this.handle(Actions.LoadedWebBookingSuccessfully.actionType, this._onWebBookingFetched);
        this.handle(
            Actions.LoadingWebBookingDayStates.actionType,
            this._onLoadingWebBookingDayStates
        );
        this.handle(Actions.LoadingDayStatesFailed.actionType, this._onLoadingDayStatesFailed);
        this.handle(
            Actions.LoadedDayStatesSuccessfully.actionType,
            this._onWebBookingDayStatesFetched
        );
        this.handle(
            Actions.LoadedCustomerContactFailed.actionType,
            this._onLoadedCustomerContactFailed
        );
    }

    private _onLoadedCustomerContactFailed = () => {
        this.emitChange();
    };

    private _onLoadingWebBookingUnitsFailed = (action: Actions.LoadingAvailableTimesFailed) => {
        const loadedKey = `webBookingUnits:${this.getKeyParams(
            action.dates,
            action.unitIds,
            action.sectionIds
        )}`;
        this.setStatus(this.NotLoaded, loadedKey);
        this.emitChange();
    };

    private _onLoadingWebBookingUnits = (action: Actions.LoadingAvailableTimes) => {
        const loadedKey = `webBookingUnits:${this.getKeyParams(
            action.dates,
            action.unitIds,
            action.sectionIds
        )}`;
        this.setStatus(this.Loading, loadedKey);
        this.emitChange();
    };

    private _onLoadingWebBookingDayStates = (action: Actions.LoadingWebBookingDayStates) => {
        const monthKey = this.getMonthKey(action.fromDate.toDate());
        this.setStatus(this.Loading, monthKey);
        this.emitChange();
    };

    private _onWebBookingDayStatesFetched = (action: Actions.LoadedDayStatesSuccessfully) => {
        const currentDate = action.fromDate.clone().local();

        const maxDate = action.dayStates.maxBy((ds) => ds.day).day;

        while (currentDate < maxDate) {
            const monthKey = this.getMonthKey(currentDate.toDate());
            const dayStates = <List<DayState>>(
                action.dayStates.filter((ds) => ds.day.isSame(currentDate, "month"))
            );
            this._webBookingDayStatesMap = this._webBookingDayStatesMap.set(monthKey, dayStates);
            this.setStatus(this.Loaded, monthKey);
            currentDate.add(1, "month");
        }
        this._loadedInitalDayState = true;
        this.emitChange();
    };

    private _onLoadingDayStatesFailed = (action: Actions.LoadingDayStatesFailed) => {
        const monthKey = this.getMonthKey(action.fromDate.toDate());
        this.setStatus(this.NotLoaded, monthKey);
        this.emitChange();
    };

    private _onWebBookingUnitsFetched = (action: Actions.LoadedAvailableTimesSuccessfully) => {
        this._webBookingUnits = action.webBookingUnits;
        const loadedKey = `webBookingUnits:${this.getKeyParams(
            action.dates,
            action.unitIds,
            action.sectionIds
        )}`;
        this.setStatus(this.Loaded, loadedKey);
        this.emitChange();
    };

    private _onWebBookingFetched = (action: Actions.LoadedWebBookingSuccessfully) => {
        this._webBooking = action.webBooking;
        this.setStatus(this.Loaded, `webBooking:${action.webBooking.guid}`);
        this.emitChange();
    };

    flushData(): void {
        this.resetStatuses();
        this._webBookingUnits = List<WebBookingUnit>();
        //this.emitChange();
    }

    getKeyParams(dates: List<IMoment>, unitIds: List<number>, sectionIds: List<number>): string {
        return `dates=${dates.map((date) => date.format("YYYYMMDD")).join(",")}&unitsIds=${unitIds
            .filter((id) => !!id)
            .map((id) => id.toString())
            .join(",")}&sectionIds=${sectionIds.map((id) => id.toString()).join(",")}`;
    }

    getTerminalUrl(
        guid: string,
        parameters: WebBookingPaymentTerminalParameters,
        system: string
    ): string {
        const loadedKey = `terminalUrl:${guid}`;
        if (this.getStatus(loadedKey) === this.NotLoaded) {
            // load from API
            this.setStatus(this.Loading, loadedKey);
            WebBookingAPI.loadTerminalUrl(guid, parameters, system);
        }

        return "";
    }

    getWebBookingUnitsResult(
        dates: List<IMoment>,
        unitIds: List<number>,
        sectionIds: List<number>
    ): Result<List<WebBookingUnit>> {
        const loadedKey = `webBookingUnits:${this.getKeyParams(dates, unitIds, sectionIds)}`;
        return new Result<List<WebBookingUnit>>({
            status: this.getStatus(loadedKey),
            value: this._webBookingUnits,
        });
    }

    getLoadedMonthDayStateResult(
        moment: IMoment,
        unitId: number,
        sectionIds: List<number>,
        system: string
    ): Result<List<DayState>> {
        const monthKey = this.getMonthKey(moment.toDate());
        const status = this.getStatus(monthKey);
        if (status === this.NotLoaded) {
            // load from API
            this.setStatus(this.Loading, monthKey);
            loadDayStates(
                moment.clone().startOf("month"),
                moment.clone().endOf("month"),
                List([unitId]),
                sectionIds,
                system
            );
        }
        // return nothing
        return new Result<List<DayState>>({
            status: this.getStatus(monthKey),
            value: List<DayState>(),
        });
    }

    getViewedMonthDayStates(): Map<string, List<DayState>> {
        return this._webBookingDayStatesMap;
    }

    getMonthKey(date: Date): string {
        return `ds:${date.getFullYear()}_${date.getMonth() + 1}`;
    }

    getWebBookingResult(bookingPublicId: string, system: string): Result<WebBooking> {
        if (this.getStatus(`webBooking:${bookingPublicId}`) === this.NotLoaded) {
            // load from API
            this.setStatus(this.Loading, `webBooking:${bookingPublicId}`);
            WebBookingAPI.loadWebBooking(bookingPublicId, system);
        }

        const status = this.getStatus(`webBooking:${bookingPublicId}`);
        const value = this._webBooking;
        const result = new Result<WebBooking>({
            status,
            value,
        });

        return result;
    }

    getWebBookingUnits(): List<WebBookingUnit> {
        return this._webBookingUnits;
    }

    isUnitsInNeedOfLoading(
        dates: List<IMoment>,
        unitIds: List<number>,
        sectionIds: List<number>
    ): boolean {
        const loadedKey = `webBookingUnits:${this.getKeyParams(dates, unitIds, sectionIds)}`;
        const status = this.getStatus(loadedKey);
        return !(status === this.Loaded || status === this.Loading);
    }

    public getState(): IWebBookingState {
        return {
            webBooking: this._webBooking,
            webBookingDayStatesMap: this._webBookingDayStatesMap,
            webBookingLoadedMonthDayStates: this._webBookingLoadedMonthDayStates,
            webBookingUnits: this._webBookingUnits,
            loadedInitalDayState: this._loadedInitalDayState,
        };
    }
}

const webBookingViewStore = new WebBookingStore();
export default webBookingViewStore;
