import moment from "moment-timezone";

import { WebFlags } from "old/models/article";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingArticle {
    articleId: number;
    articleGroupId: number;
    articleGroupName: string;
    departmentId: string;
    unitId: number;
    start: IMoment;
    end: IMoment;
    name: string;
    tableName: string;
    tableNumber: string;
    tableChairs: number;
    message: string;
    quantity: number;
    isTable: boolean;
    outPriceIncVat: number;
    vat: number;
    menuGuid: string;
    webFlags: WebFlags;
}

const WebBookingArticleRecord = RecordFactory<IWebBookingArticle>({
    articleId: -1,
    articleGroupId: 0,
    articleGroupName: "",
    unitId: -1,
    departmentId: "",
    start: moment.invalid(),
    end: moment.invalid(),
    name: "",
    tableName: "",
    tableNumber: "",
    tableChairs: -1,
    message: "",
    quantity: 0,
    isTable: true,
    outPriceIncVat: 0,
    vat: 0,
    menuGuid: "",
    webFlags: WebFlags.ShowTextPriceQuantity,
});

export class WebBookingArticle extends WebBookingArticleRecord implements IWebBookingArticle {
    constructor(values: Partial<IWebBookingArticle>) {
        if (values) {
            values.start = moment(values.start, "YYYY-MM-DDTHH:mm:ss");
            values.end = moment(values.end, "YYYY-MM-DDTHH:mm:ss");
        }
        super(values);
    }

    public set<K extends keyof IWebBookingArticle, V extends IWebBookingArticle[K]>(
        key: K,
        value: V
    ): this {
        if (key === "start" || key === "end") {
            const start = key === "start" ? <IMoment>value : this.start;
            const end = key === "end" ? <IMoment>value : this.end;
            return super.set(key, value);
        }

        return super.set(key, value);
    }
}

export function createdByMenu(bookedArticle: WebBookingArticle): boolean {
    return bookedArticle && bookedArticle.menuGuid && bookedArticle.menuGuid.length > 10;
}
