import { RecordFactory } from "old/common/modules/recordFactory";

export enum WebBookingErrorCodeEnum {
    Undefined = 0,
    FullyBooked = 1,
}

export type IWebBookingError = {
    code: WebBookingErrorCodeEnum;
    message: string;
};

const WebBookingErrorRecord = RecordFactory<IWebBookingError>({
    code: WebBookingErrorCodeEnum.Undefined,
    message: "",
});

export class WebBookingError extends WebBookingErrorRecord {}
