import React from 'react';
import rg4js from 'raygun4js';

type ErrorBoundaryProps = {
  fallback: React.ReactNode;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    if (error instanceof Error) {
      rg4js('send', {
        error,
        tags: ['error_boundary'],
      });
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      const { fallback } = this.props;
      return fallback;
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
