import { WebBookingContact } from "old/models/webBookingContact";
import { WebBookingCreateParametersRuleTime } from "old/models/webBookingCreateParametersRuleTime";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";
import { offsetBetweenTimeZones } from "old/modules/timeHelper";
import { List } from "immutable";
import moment from "moment-timezone";

export interface IWebBookingCreateParameters {
    timeSetDate: IMoment;
    guests: number | null;
    guestsChildren: number | null;
    times: List<WebBookingCreateParametersRuleTime>;
    contact: WebBookingContact;
    customer?: WebBookingCustomer;
    message: string;
    logMessage: string;
    lang: string;
    isPending?: boolean;
    isWaitList: boolean;
    isCompanyCustomer: boolean;
    invoiceMarking: string;
    wishToBeInvoiced: boolean;
}

const WebBookingCreateParametersRecord = RecordFactory<IWebBookingCreateParameters>({
    timeSetDate: moment.invalid(),
    guests: null,
    guestsChildren: null,
    times: List<WebBookingCreateParametersRuleTime>(),
    contact: new WebBookingContact(),
    customer: new WebBookingCustomer(),
    message: "",
    logMessage: "",
    lang: "",
    isPending: false,
    isWaitList: false,
    isCompanyCustomer: false,
    invoiceMarking: "",
    wishToBeInvoiced: false,
});

export class WebBookingCreateParameters
    extends WebBookingCreateParametersRecord
    implements IWebBookingCreateParameters
{
    constructor(values?: Partial<IWebBookingCreateParameters>) {
        if (values?.times) {
            values.times = List<WebBookingCreateParametersRuleTime>(
                values.times.map((m: any) => new WebBookingCreateParametersRuleTime(m))
            );
        }
        super(values);
    }

    public toJS(): any {
        const jsObj: any = super.toJS();

        jsObj.timeSetDate = this.timeSetDate.format("YYYY-MM-DD[T]HH:mm:ss");

        jsObj.times = jsObj.times.map((time: any) => {
            return {
                ruleId: time.ruleId,
                start: time.start.format("YYYY-MM-DD[T]HH:mm:ss"), // Throw away timezone
            };
        });

        return jsObj;
    }

    // Take possible timezone difference into account when checking if still valid
    private getTzOffset() {
        const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Europe/Stockholm";
        return offsetBetweenTimeZones(clientTz);
    }

    private validateErrors(validationResult: ValidationResult): ValidationResult {
        if (this.times == null || this.times.count() === 0) {
            validationResult = validationResult.addPropertyError("times", "");
            validationResult = validationResult.addGeneralError("validation.notTimesToBook");
        }

        if (
            this.times.some(
                (t) => t.availableUntil < moment().add(this.getTzOffset() ?? 0, "minutes")
            )
        ) {
            validationResult = validationResult.addPropertyError("times", "");
            validationResult = validationResult.addGeneralError(
                "validation.availableUntilHasPassed"
            );
        }

        if (this.contact == null) {
            validationResult = validationResult.addGeneralError("validation.noContact");
        }

        if (this.guests < 0) {
            validationResult = validationResult.addPropertyError("guests", "");
            validationResult = validationResult.addGeneralError("validation.invalidGuests");
        }

        if (this.guestsChildren < 0) {
            validationResult = validationResult.addPropertyError("guestsChildren", "");
            validationResult = validationResult.addGeneralError("validation.invalidGuestsChildren");
        }

        if (this.guestsChildren > this.guests) {
            validationResult = validationResult.addPropertyError("guestsChildren", "");
            validationResult = validationResult.addGeneralError("validation.lessGuestsChildren");
        }

        if (this.message.length > 255) {
            validationResult = validationResult.addPropertyError("message", "");
            validationResult = validationResult.addGeneralError("validation.message");
        }

        return validationResult;
    }

    private validateWarnings(validationResult: ValidationResult): ValidationResult {
        return validationResult;
    }

    public validate(errors: boolean = true, warnings: boolean = true): ValidationResult {
        let validationResult = new ValidationResult();

        if (errors) {
            validationResult = this.validateErrors(validationResult);
        }

        if (warnings) {
            validationResult = this.validateWarnings(validationResult);
        }

        return validationResult;
    }
}
