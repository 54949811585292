import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingCustomer {
    name: string;
    phone: string;
    email: string;
    streetaddress: string;
    zipcode: string;
    city: string;
    orgNr: string;
    invoiceStreetaddress: string;
    invoiceMarking: string;
}

const WebBookingCustomerRecord = RecordFactory<IWebBookingCustomer>({
    name: "",
    phone: "",
    email: "",
    streetaddress: "",
    zipcode: "",
    city: "",
    orgNr: "",
    invoiceStreetaddress: "",
    invoiceMarking: "",
});

export class WebBookingCustomer extends WebBookingCustomerRecord implements IWebBookingCustomer {
    constructor(values: Partial<IWebBookingCustomer> = {}) {
        super(values);
    }
}
