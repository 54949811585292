import { RecordFactory } from "old/common/modules/recordFactory";
import { Set } from "immutable";

export interface IPropertyError {
    propertyName: string;
    message: string;
    messageData: Record<string, any>;
    dependsOnProperties: Set<string>;
}

const PropertyErrorRecord = RecordFactory<IPropertyError>({
    propertyName: "",
    message: "",
    messageData: {},
    dependsOnProperties: Set<string>(),
});

export class PropertyError extends PropertyErrorRecord implements IPropertyError {
    constructor(values?: Partial<IPropertyError>) {
        super(values);
    }
}
