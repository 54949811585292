import * as Actions from "old/actions/bookingMenuExternalActions";
import BookingMenuExternalAPI from "old/api/bookingMenuExternalAPI";
import { BookingMenuExternal } from "old/models/bookingMenuExternal";
import { Result } from "old/framework/models/result";
import Store from "old/framework/stores/store";

interface IBookingMenuExternalStore extends Store {
    getBookingMenuExternalResult(id: string, system: string): Result<BookingMenuExternal>;
}

class BookingMenuExternalStore extends Store implements IBookingMenuExternalStore {
    private _bookingMenu: BookingMenuExternal;

    constructor() {
        super();
        this._bookingMenu = null;

        this.handle(Actions.LoadedSuccessfully.actionType, this._onBookingMenuFetched);
        this.handle(Actions.UpdatedSuccessfully.actionType, this._onBookingMenuFetched);
    }

    private _onBookingMenuFetched = (action: Actions.LoadedSuccessfully) => {
        this._bookingMenu = action.bookingMenu;
        this.setStatus(this.Loaded);
        this.emitChange();
    };

    getBookingMenuExternalResult(id: string, system: string): Result<BookingMenuExternal> {
        if (this.getStatus() === this.Loaded) {
            return new Result<BookingMenuExternal>({
                status: this.getStatus(),
                value: this._bookingMenu,
            });
        } 
            this.setStatus(this.Loading);
            BookingMenuExternalAPI.load(id, system);
            return new Result<BookingMenuExternal>({
                status: this.Loading,
                value: new BookingMenuExternal({}),
            });
        
    }
}

export default new BookingMenuExternalStore();
