import moment from "moment-timezone";
import { List, Map, Set } from "immutable";
import { translate as t } from "old/common/mixins/localeHelper";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";
import { TableInfo } from "./table";

export enum ArticlePriceModel {
    Styckpris = 0,
    Timpris = 1,
}

export enum ArticleRounding {
    Round1Minute = 1,
    Round15Minutes = 15,
    Round30Minutes = 30,
    Round60Minutes = 60,
    Round120Minutes = 120,
    Round240Minutes = 240,
}

export enum WebFlags {
    ShowTextPriceQuantity = 0,
    ShowTextQuantity = 1,
    ShowText = 2,
    Hide = 3,
}

export enum ArticlePackage {
    None = 0,
    Template = 1,
    Package = 2,
}

export enum StockType {
    None = 0,
    Time = 1,
    Rolling = 2,
    Absolute = 3,
}

export enum ArticleFlags {
    None = 0,
    CorporateGroupExactMatch = 1 << 0,
    CorporateGroupFuzzyMatch = 1 << 1,
}

export interface ISubArticle {
    id: number | null; //the id in bookingrelartart
    inPrice: number;
    information: string;
    parentArticleId: number; //the package article id in bookingarticles
    quantity: number;
    overridePrice: number;
    subArticleId: number; //the sub article id in bookingarticles
    discount: number;
    offsetMinutes: number;
}

const SubArticleRecord = RecordFactory<ISubArticle>({
    id: null,
    subArticleId: -1,
    parentArticleId: -1,
    quantity: 1,
    overridePrice: 0,
    discount: 0,
    offsetMinutes: 0,
    information: "",
    inPrice: 0,
});

let newSubArticleId = -1;

export class SubArticle extends SubArticleRecord implements ISubArticle {
    constructor(values: Partial<ISubArticle>) {
        if (!values.id) values.id = newSubArticleId--;
        super(values);
    }

    getOutPrice(): number {
        return (this.overridePrice - this.discount) * this.quantity;
    }

    isInfiniteRecursion(
        checkArticle: Article,
        allArticles: Map<number, Article>
    ): string | undefined {
        let result = undefined;
        const article = allArticles.get(this.subArticleId);
        article.subArticles.forEach((subArticle) => {
            if (
                checkArticle.id === subArticle.subArticleId ||
                article.isInfiniteRecursion(checkArticle, allArticles)
            ) {
                result = article.name;
            }
        });
        return result;
    }
}

export interface IArticle {
    articleGroupId: number;
    articleNumber: string;
    canChangePrice: boolean;
    canChangeTax: boolean;
    copyInformation: boolean;
    dayBreakTime: string | null;
    deliverOnStartTime: boolean;
    departmentIds: Set<number>;
    description: string;
    furnishingIds: Set<number>;
    hidden: boolean;
    id: number;
    image: string | null;
    imageFilename: string | null;
    imageUploadData: string | null;
    imageUrl: string | null;
    inPrice: number;
    inactivatedUntil: IMoment;
    includedInResourcePool: boolean;
    /** Fysisk plats, om den är kopplad till ett resurs i bordskartan */
    isContainer: boolean;
    isDayBreak: boolean;
    isPackage: ArticlePackage;
    isRentalArticle: boolean;
    isSubInvoice: boolean;
    keepStockBalance: boolean;
    name: string;
    preparationTime: number;
    price: number;
    priceIsIncludingVat: boolean;
    priceModel: ArticlePriceModel;
    // TODO: Maybe change to enum?
    receiptText: string;
    restorationTime: number;

    roundUpTo: ArticleRounding;

    showPrinting: boolean;
    sortOrder: number;
    specialFood: boolean;
    stockBalance: number;
    stockType: StockType;
    subArticles: List<SubArticle>;
    supplierArticleNumber: string;
    supplierId: number;
    unit: string;
    useCalculatedInPrice: boolean;
    vatPercent: number;
    webFlags: WebFlags;
    tableInfos: List<TableInfo>;
    centralKey: string;
    binaryFlags: ArticleFlags;
}

const ArticleRecord = RecordFactory<IArticle>({
    id: -1,
    name: "",
    articleGroupId: -1,
    sortOrder: 0,
    departmentIds: Set<number>(),
    description: "",
    price: 0,
    inPrice: 0,
    useCalculatedInPrice: false,
    priceIsIncludingVat: true,
    vatPercent: 25,
    articleNumber: "",
    supplierArticleNumber: "",
    supplierId: -1,
    subArticles: List<SubArticle>(),
    unit: "",
    receiptText: "",
    keepStockBalance: false,
    hidden: false,
    furnishingIds: Set<number>(),
    canChangeTax: false,
    canChangePrice: true,
    priceModel: ArticlePriceModel.Styckpris,
    roundUpTo: ArticleRounding.Round1Minute,
    isDayBreak: false,
    dayBreakTime: null,
    preparationTime: 0,
    restorationTime: 0,
    stockBalance: 0,
    stockType: StockType.None,
    includedInResourcePool: false,
    isRentalArticle: false,
    deliverOnStartTime: false,
    isContainer: false,
    showPrinting: true,
    specialFood: false,
    copyInformation: false,
    isSubInvoice: false,
    webFlags: WebFlags.ShowTextPriceQuantity,
    isPackage: ArticlePackage.None,
    imageUrl: null,
    imageFilename: null,
    imageUploadData: null,
    image: null,
    inactivatedUntil: moment.invalid(),
    tableInfos: List<TableInfo>(),
    centralKey: "",
    binaryFlags: 0,
});

let newArticleId = -1;

export class Article extends ArticleRecord implements IArticle {
    constructor(values?: Partial<IArticle>) {
        if (values) {
            if (!values.id) values.id = newArticleId--;
            values.inactivatedUntil = moment(values.inactivatedUntil, "YYYY-MM-DDTHH:mm:ss");
            values.departmentIds = values.departmentIds ? Set(values.departmentIds) : Set();
            values.subArticles = values.subArticles
                ? List(values.subArticles.map((sa) => new SubArticle(sa)))
                : List();
            values.tableInfos = values.tableInfos
                ? List(
                      values.tableInfos.map((t) => {
                          return new TableInfo(t);
                      })
                  )
                : List();
            values.furnishingIds = values.furnishingIds ? Set(values.furnishingIds) : Set();
        } else {
            values = { id: newArticleId-- };
        }

        super(values);
    }

    getInPrice(allArticles: Map<number, IArticle>): number {
        if (this.useCalculatedInPrice) {
            let inPrice = 0;
            this.subArticles.forEach((subArticle) => {
                const a = allArticles.get(subArticle.subArticleId);
                if (a.priceModel === ArticlePriceModel.Styckpris) {
                    inPrice += subArticle.quantity * a.inPrice;
                }
            });
            return inPrice;
        }
        return this.inPrice;
    }

    rebuildOutPrices(): Article {
        if (this.isPackage === ArticlePackage.Package) {
            return super.set("price", this.getSubArticleSum());
        }
        return this;
    }

    isInfiniteRecursion(
        checkArticle: Article,
        allArticles: Map<number, Article>
    ): string | undefined {
        let result = undefined;
        this.subArticles.forEach((subArticle) => {
            if (
                this.id === subArticle.subArticleId ||
                subArticle.isInfiniteRecursion(checkArticle, allArticles)
            ) {
                result = this.name;
            }
        });
        return result;
    }

    getSubArticleSum(): number {
        return this.subArticles
            .map((subArticle) => subArticle.getOutPrice())
            .reduce(
                (previousValue: number, currentValue: number) => previousValue + currentValue,
                0
            );
    }

    probablyATable(): boolean {
        return this.isContainer && this.stockBalance === 1 && this.price === 0;
    }

    outOfStock(): boolean {
        return this.inactivatedUntil > moment();
    }

    probablyAResourcePool(): boolean {
        return this.isContainer && this.stockBalance >= 1;
    }

    public validate(
        errors: boolean = true,
        warnings: boolean = true,
        informations: boolean = true,
        allArticles: Map<number, Article>
    ): ValidationResult {
        let validationResult = new ValidationResult();

        if (errors) {
            validationResult = this.validateErrors(validationResult, allArticles);
        }

        if (warnings) {
            validationResult = this.validateWarnings(validationResult, allArticles);
        }

        if (informations) {
            //validationResult = this.validateInformation(validationResult);
        }

        return validationResult;
    }

    public validateCentralDiff(centralArticles?: Map<string, Article>): ValidationResult {
        let validationResult = new ValidationResult();
        if (!centralArticles) return validationResult;
        const centralArticle = centralArticles.get(this.centralKey);
        if (!centralArticle) return validationResult;

        if (this.name !== centralArticle.name) {
            validationResult = validationResult.addPropertyWarning(
                "name",
                t("articles.administration.articles.edit.validation.centralArticleValue", {
                    value: centralArticle.name,
                    propName: t("articles.administration.articles.name"),
                })
            );
        }
        if (this.price !== centralArticle.price) {
            validationResult = validationResult.addPropertyWarning(
                "price",
                t("articles.administration.articles.edit.validation.centralArticleValue", {
                    value: centralArticle.price,
                    propName: t("articles.administration.articles.price"),
                })
            );
        }

        return validationResult;
    }

    private validateErrors(validationResult: ValidationResult, allArticles: Map<number, Article>) {
        if (this.name.trim() === "") {
            validationResult = validationResult.addPropertyError(
                "name",
                t("articles.administration.articles.edit.nameRequired")
            );
        }

        if (this.articleGroupId <= 0) {
            validationResult = validationResult.addPropertyError(
                "articleGroupId",
                t("articles.administration.articles.edit.articleGroupRequired")
            );
        }

        let sameName = false;
        let sameNumber = false;

        if (
            this.articleNumber &&
            allArticles
                .valueSeq()
                .some((a) => a.id !== this.id && a.articleNumber === this.articleNumber)
        ) {
            sameNumber = true;
        }

        if (
            this.name &&
            allArticles
                .valueSeq()
                .some(
                    (a) =>
                        a.id !== this.id &&
                        a.name.toLocaleLowerCase() === this.name.toLocaleLowerCase() &&
                        a.articleGroupId === this.articleGroupId
                )
        ) {
            sameName = true;
        }

        if (sameName && sameNumber) {
            if (
                this.name &&
                this.articleNumber &&
                allArticles
                    .valueSeq()
                    .some(
                        (a) =>
                            a.id !== this.id &&
                            a.name.toLocaleLowerCase() === this.name.toLocaleLowerCase() &&
                            a.articleNumber === this.articleNumber
                    )
            ) {
                validationResult = validationResult.addCombinedPropertyError(
                    ["name", "articleNumber"],
                    t(
                        "articles.administration.articles.edit.articleWithSameNameAndNumberAlreadyExists"
                    )
                );
            }
        } else if (sameNumber) {
            validationResult = validationResult.addPropertyError(
                "articleNumber",
                t("articles.administration.articles.edit.articleWithSameNumberAlreadyExists")
            );
        } else if (sameName) {
            validationResult = validationResult.addPropertyError(
                "name",
                t("articles.administration.articles.edit.articleWithSameNameAlreadyExists")
            );
        }

        if (this.subArticles.some((subArticle) => !allArticles.get(subArticle.subArticleId))) {
            validationResult = validationResult.addPropertyError(
                "subArticles",
                t("articles.administration.articles.edit.subArticle.selectSubArticle")
            );
        }
        const conflictArticleName = this.isInfiniteRecursion(this, allArticles);
        if (conflictArticleName) {
            validationResult = validationResult.addPropertyError(
                "subArticles",
                t("articles.administration.articles.edit.subArticle.isInfiniteRecursion", {
                    articlename: conflictArticleName,
                })
            );
        }

        if (this.isPackage === ArticlePackage.Package) {
            if (this.price !== this.getSubArticleSum())
                validationResult = validationResult.addPropertyError(
                    "subArticles",
                    t("articles.administration.articles.edit.subArticleSumMismatch", {
                        price: this.price,
                        subArticleSum: this.getSubArticleSum(),
                    })
                );
        }

        if (this.isPackage === ArticlePackage.Package) {
            const vatErrorSubArticles: string[] = [];
            const webFlagErrorSubArticles: string[] = [];
            this.subArticles.forEach((subArticle) => {
                const article = allArticles.get(subArticle.subArticleId);
                if (article.priceIsIncludingVat !== this.priceIsIncludingVat)
                    vatErrorSubArticles.push(article.name);
                if (article.webFlags !== this.webFlags) webFlagErrorSubArticles.push(article.name);
            });

            if (vatErrorSubArticles.length > 0)
                validationResult = validationResult.addPropertyError(
                    "subArticles",
                    t("articles.administration.articles.edit.subArticle.validation.vatError", {
                        articles: vatErrorSubArticles.join(", "),
                    })
                );
            if (webFlagErrorSubArticles.length > 0)
                validationResult = validationResult.addPropertyError(
                    "subArticles",
                    t("articles.administration.articles.edit.subArticle.validation.webFlagError", {
                        articles: vatErrorSubArticles.join(", "),
                    })
                );
        }
        const vatErrorParentArticles: string[] = [];
        allArticles.forEach((article) => {
            if (
                article.isPackage === ArticlePackage.Package &&
                article.subArticles.findIndex((sA) => sA.subArticleId === this.id) !== -1 &&
                article.priceIsIncludingVat !== this.priceIsIncludingVat
            )
                vatErrorParentArticles.push(article.name);
        });
        if (vatErrorParentArticles.length > 0)
            validationResult = validationResult.addPropertyError(
                "priceIsIncludingVat",
                t("articles.administration.articles.edit.validation.vatErrorParentArticle", {
                    articles: vatErrorParentArticles.join(", "),
                })
            );

        return validationResult;
    }

    private validateWarnings(
        validationResult: ValidationResult,
        allArticles: Map<number, Article>
    ) {
        if (this.isPackage === ArticlePackage.Package) {
            if (this.price !== this.getSubArticleSum())
                validationResult = validationResult.addPropertyWarning(
                    "subArticles",
                    t("articles.administration.articles.edit.subArticleSumMismatch", {
                        price: this.price,
                        subArticleSum: this.getSubArticleSum(),
                    })
                );
        }

        return validationResult;
    }
}
