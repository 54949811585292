import { Button, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useConfirmWaitListBooking, useWebBooking } from "hooks";
import {
    usePaymentTerminalPaymentLink,
    usePaymentTerminalWaitList,
} from "hooks/api/payment-terminal";
import { ConditionRulePayment as paymentType } from "old/models/webRuleConditionRulePaymentEnum";
import { useTranslation } from "react-i18next";
import { GUID_STORAGE_KEY } from "store";
import { getUrlParams } from "utils";
import {
    hasUnhandledNoShowLink,
    hasUnhandledPaymentEventLink,
    hasUnpaidPrepayment,
    isNoShowLink,
    isPaymentEventExpired,
    isWaitList,
} from "utils/booking";
import { getPaymentCalculationTotal } from "utils/payment-utils";

const BookingActionButton = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { mutate: confirmMutate, isPending: confirmIsLoading } = useConfirmWaitListBooking();
    const terminalWaitList = usePaymentTerminalWaitList();
    const terminal = usePaymentTerminalPaymentLink();
    const { paymentLink } = getUrlParams();

    const isPaymentLink = Boolean(paymentLink);
    const hasLinkPassed = isPaymentEventExpired(webBooking, isPaymentLink);
    const rule = webBooking?.webTimeRules.first();

    const ruleTotal = getPaymentCalculationTotal(
        rule as any,
        webBooking.guests,
        webBooking?.articles?.filter((x) => x.isTable) as any
    );

    const linkTotal = webBooking.paymentEvents.last()?.requestedAmount;

    const cardRegistration =
        (rule && rule.didShowUpRuleType === paymentType.NoShowFee) ||
        hasUnhandledNoShowLink(webBooking);
    const prepayment =
        (rule && rule.didShowUpRuleType === paymentType.PrePayment) ||
        hasUnpaidPrepayment(webBooking);

    const getButtonText = () => {
        if (prepayment) {
            return t("payAmount", {
                amount: t("currency.onlyAmount", {
                    value: ruleTotal || linkTotal,
                }),
            });
        }
        if (cardRegistration) {
            return t("registerPaymentCard");
        }
        return t("waitList.confirmBooking");
    };

    const isWaitListBooking = isWaitList(webBooking);
    const onActionClick = () => {
        if (hasUnhandledPaymentEventLink(webBooking, isPaymentLink) && !isWaitListBooking) {
            localStorage.setItem(GUID_STORAGE_KEY, webBooking.guid);
            terminal.mutate({});
        } else if (
            rule &&
            isWaitListBooking &&
            (rule.didShowUpRuleType === paymentType.PrePayment ||
                rule.didShowUpRuleType === paymentType.NoShowFee)
        ) {
            terminalWaitList.mutate({});
        } else {
            confirmMutate();
        }
    };

    const isNoShowPaymentLink = isNoShowLink(webBooking);
    const linkExpirationDate =
        webBooking.chargePaymentLinkExpirationDate?.format("YYYY-MM-DD HH:mm");
    return (
        <Stack spacing={ThemeSpaceVariable.Small}>
            <Button
                variant="primary"
                isLoading={confirmIsLoading || terminalWaitList.isPending || terminal.isPending}
                onClick={onActionClick}
                isDisabled={hasLinkPassed}
                size="lg"
            >
                {getButtonText()}
            </Button>
            {hasUnhandledPaymentEventLink(webBooking, isPaymentLink) &&
                !isWaitListBooking &&
                linkExpirationDate && (
                    <Text align="center" color={ThemeColorVariable.OnSurfaceSubdued}>
                        {isNoShowPaymentLink
                            ? t("payment.cardRegistrationPossibleTo", { date: linkExpirationDate })
                            : t("payment.prepaymentPossibleTo", { date: linkExpirationDate })}
                    </Text>
                )}
        </Stack>
    );
};

export default BookingActionButton;
