import { RecordFactory } from "old/common/modules/recordFactory";
import { LoadingStatus } from "old/framework/models/loadingStatusEnum";
import { ResultStatus } from "old/models/resultStatusEnum";

export interface IResult<T> {
    status: LoadingStatus | ResultStatus;
    statusMessage?: string | null;
    value: T;
}

const ResultRecord = RecordFactory<IResult<any>>({
    status: LoadingStatus.NotLoaded,
    statusMessage: null,
    value: null,
});

/**
 *
 * @export
 * @class Result
 * @extends {ResultRecord}
 * @implements {IResult<T>}
 * @template T Type of value
 */
export class Result<T> extends ResultRecord implements IResult<T> {
    /**
     * Creates an instance of Result.
     * @param {Partial<IResult<T>>} [values]
     * @summary Previously used in getters in stores (still used in old code). This is generally bad practice - getters should always return the same reference to unchanged state
     * @memberof Result
     */
    constructor(values?: Partial<IResult<T>>) {
        super(values);
    }

    public isNotLoaded(): boolean {
        return this.status === LoadingStatus.NotLoaded;
    }

    public isLoaded(): boolean {
        return this.status === LoadingStatus.Loaded;
    }

    public isLoading(): boolean {
        return this.status === LoadingStatus.Loading;
    }

    public isError(): boolean {
        return this.status === LoadingStatus.Error;
    }

    public static NotLoaded<T>(): Result<T> {
        return new Result<T>({ status: LoadingStatus.NotLoaded });
    }

    public static Loading<T>(): Result<T> {
        return new Result<T>({ status: LoadingStatus.Loading });
    }

    public static Loaded<T>(value: T, statusMessage?: string): Result<T> {
        return new Result<T>({
            status: LoadingStatus.Loaded,
            value: value,
            statusMessage: statusMessage,
        });
    }

    public static Error<T>(statusMessage?: string): Result<T> {
        return new Result<T>({ status: LoadingStatus.Error, statusMessage: statusMessage });
    }
}
