import { RecordFactory } from "old/common/modules/recordFactory";
import WebBookingUnavailabilityReason from "old/models/webBookingUnavailabilityReason";

export interface IWebBookingUnavailability {
    from: number | null;
    to: number | null;
    reason: WebBookingUnavailabilityReason;
}

const WebBookingUnavailabilityRecord = RecordFactory<IWebBookingUnavailability>({
    from: null,
    to: null,
    reason: WebBookingUnavailabilityReason.Unknown,
});

export class WebBookingUnavailability
    extends WebBookingUnavailabilityRecord
    implements IWebBookingUnavailability
{
    constructor(values?: Partial<IWebBookingUnavailability>) {
        super(values);
    }
}
