import { RecordFactory } from "old/common/modules/recordFactory";

export interface IMandatoryWebBookingContactFields {
    fname: boolean;
    lname: boolean;
    mobile: boolean;
    email: boolean;
    streetaddress: boolean;
    zipcode: boolean;
    city: boolean;
    deliveryInfo: boolean;
    customerName: boolean;
    organizationCode: boolean;
    invoiceStreetAddress: boolean;
    invoiceEmail: boolean;
}

const MandatoryWebBookingContactFieldsRecord = RecordFactory<IMandatoryWebBookingContactFields>({
    fname: true,
    lname: true,
    mobile: true,
    email: true,
    streetaddress: false,
    zipcode: false,
    city: false,
    deliveryInfo: false,
    customerName: false,
    organizationCode: false,
    invoiceStreetAddress: false,
    invoiceEmail: false,
});

export class MandatoryWebBookingContactFields
    extends MandatoryWebBookingContactFieldsRecord
    implements IMandatoryWebBookingContactFields
{
    constructor(values?: Partial<IMandatoryWebBookingContactFields>) {
        super(values);
    }
}
