import Action from "old/framework/actions/action";

import { BookingMenuExternal } from "old/models/bookingMenuExternal";
import { List } from "immutable";

export class LoadedSuccessfully extends Action {
    static actionType: string = "bookingMenu_loaded_successfully";

    constructor(public bookingMenu: BookingMenuExternal) {
        super(false);
        this.doDispatch();
    }
}

export class Respond extends Action {
    static actionType: string = "bookingMenu_respond";

    constructor(public bookingMenu: BookingMenuExternal, public respondSilent: boolean) {
        super(false);
        this.doDispatch();
    }
}

export class UpdatedSuccessfully extends Action {
    static actionType: string = "bookingMenu_updated_successfully";

    constructor(public bookingMenus: List<BookingMenuExternal>, public respondSilent: boolean) {
        super(false);
        this.doDispatch();
    }
}

export class UpdateFailed extends Action {
    static actionType: string = "bookingMenu_update_failed";

    constructor(public bookingMenus: List<BookingMenuExternal>) {
        super(false);
        this.doDispatch();
    }
}

export class RespondMenuFailed extends Action {
    static actionType: string = "bookingMenu_respond_failed";

    constructor() {
        super();
    }
}
