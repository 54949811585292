import * as ExternalBookingSettingsActions from "old/actions/externalBookingSettingsActions";
import * as API from "old/framework/modules/unauthedAPIBase";

import {
    ExternalBookingSettings,
    IExternalBookingSettings,
} from "old/models/externalBookingSettings";
import { bookingLoader } from "loader";

interface IExternalBookingSettingsAPI {
    load(unitId: number, system: string): void;
}

class ExternalBookingSettingsAPI
    extends API.UnauthedAPIBase
    implements IExternalBookingSettingsAPI
{
    constructor() {
        super();
        this.setApiVersion("v1");
    }

    load = (unitId: number, system: string) => {
        const promise = bookingLoader({ unitId });
        (async () => {
            const { settings } = await promise;
            const settings2 = new ExternalBookingSettings(
                settings.toJS() as IExternalBookingSettings
            );
            new ExternalBookingSettingsActions.LoadedSuccessfully(settings2);
        })();

        return promise;
    };
}

const externalBookingSettingsAPI = new ExternalBookingSettingsAPI();
export default externalBookingSettingsAPI;
