import { RecordFactory } from "old/common/modules/recordFactory";

export interface IBoundingBox {
    height: number;
    width: number;
    xMax: number;
    xMin: number;
    yMax: number;
    yMin: number;
}

const BoundingBoxRecord = RecordFactory<IBoundingBox>({
    height: 0,
    width: 0,
    xMax: 0,
    xMin: 0,
    yMax: 0,
    yMin: 0,
});

export class BoundingBox extends BoundingBoxRecord implements IBoundingBox {
    constructor(values?: Partial<IBoundingBox>) {
        super(values);
    }

    intersects(b: BoundingBox): boolean {
        if (this.xMax < b.xMin) return false; // this is left of b
        if (this.xMin > b.xMax) return false; // this is right of b
        if (this.yMax < b.yMin) return false; // this is above b
        if (this.yMin > b.yMax) return false; // this is below b
        return true;
    }

    union(b: BoundingBox): BoundingBox {
        const xMin = Math.min(this.xMin, b.xMin);
        const xMax = Math.max(this.xMax, b.xMax);
        const yMin = Math.min(this.yMin, b.yMin);
        const yMax = Math.max(this.yMax, b.yMax);

        return new BoundingBox({
            xMin,
            yMin,
            xMax,
            yMax,
            width: Math.abs(xMax - xMin),
            height: Math.abs(yMax - yMin),
        });
    }
}
