import { useWebBooking } from "hooks";
import { useMemo } from "react";
import { useBookingStateContext } from "store";
import { useAvailableTimes } from "./api";
import { AvailableTimesSection, AvailableTimesUnit, WebBookingTimeRule } from "./api/types";

export const useSelectedRule = () => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const { data: webBooking } = useWebBooking();

    let selectedRule = useMemo(() => {
        return availableTimes?.data
            ?.flatMap((item: AvailableTimesUnit) =>
                item.sections.flatMap((section: AvailableTimesSection) => section.timeSets)
            )
            .find(
                (timeSet: WebBookingTimeRule) => timeSet.id === state?.times[0]?.ruleId
            ) as WebBookingTimeRule;
    }, [availableTimes, state.times]);

    if (webBooking?.webTimeRules) selectedRule = webBooking.webTimeRules.first()?.toJS() as any;

    return selectedRule;
};
