import danish from "translations/languages/da-DK.json";
import english from "translations/languages/en-US.json";
import spanish from "translations/languages/es-ES.json";
import estonian from "translations/languages/et-EE.json";
import finnish from "translations/languages/fi-FI.json";
import french from "translations/languages/fr-BE.json";
import norwegian from "translations/languages/nb-NO.json";
import dutch from "translations/languages/nl-BE.json";
import russian from "translations/languages/ru-RU.json";
import swedish from "translations/languages/sv-SE.json";

export const resources = {
    "en-US": {
        translation: english,
    },
    "sv-SE": {
        translation: swedish,
    },
    "nb-NO": {
        translation: norwegian,
    },
    "da-DK": {
        translation: danish,
    },
    "fi-FI": {
        translation: finnish,
    },
    es: {
        translation: spanish,
    },
    et: {
        translation: estonian,
    },
    ru: {
        translation: russian,
    },
    fr: {
        translation: french,
    },
    nl: {
        translation: dutch,
    },
} as const;

export const supportedLanguages = Object.keys(resources) as Array<keyof typeof resources>;
