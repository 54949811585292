import Action from "old/framework/actions/action";

import { ExternalBookingSettings } from "old/models/externalBookingSettings";

export class LoadedSuccessfully extends Action {
    static actionType: string = "externalBookingSettings_loaded_successfully";

    externalBookingSettings: ExternalBookingSettings;

    constructor(externalBookingSettings: ExternalBookingSettings) {
        super(false);
        this.externalBookingSettings = externalBookingSettings;
        this.doDispatch();
    }
}
