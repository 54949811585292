import { RecordFactory } from "old/common/modules/recordFactory";

export interface IGeneralMessage {
    message: string;
    messageData: Record<string, any>;
}

const GeneralMessageRecord = RecordFactory<IGeneralMessage>({
    message: "",
    messageData: {},
});

export class GeneralMessage extends GeneralMessageRecord implements IGeneralMessage {
    constructor(values?: Partial<IGeneralMessage>) {
        super(values);
    }
}
