import {
    Box,
    Divider,
    Drawer,
    Icons,
    Link,
    NavigationList,
    ThemeColorVariable,
    ThemeSpaceVariable,
    useDisclosure,
} from "caspeco-casper-ui";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import LanguageModal from "components/side-navigation/language-modal";
import { SideNavigationMenuButton } from "components/side-navigation/side-navigation-menu-button";
import { t } from "i18next";
import getNameOfCurrentLang from "../../helpers/language-helper";
import { PoweredByCaspeco } from "./powered-by-caspeco";
import { SideNavigationHeader } from "./side-navigation-header";

interface ISideNavigation {
    onCloseSideNav: () => void;
    isSideNavOpen: boolean;
}

const SideNavigation = ({ onCloseSideNav, isSideNavOpen }: ISideNavigation) => {
    // Change language modal:
    const {
        isOpen: languageModalIsOpen,
        onOpen: openLanguageModal,
        onClose: closeLanguageModal,
    } = useDisclosure({
        onOpen: onCloseSideNav,
    });
    const { isOpen: privacyPolicyModalIsOpen, onToggle: togglePrivacyPolicyModal } = useDisclosure({
        onOpen: onCloseSideNav,
    });

    return (
        <>
            <LanguageModal onClose={closeLanguageModal} isOpen={languageModalIsOpen} />
            <PrivacyPolicyModal
                isOpen={privacyPolicyModalIsOpen}
                onClose={togglePrivacyPolicyModal}
            />

            <Drawer
                isOpen={isSideNavOpen}
                onClose={onCloseSideNav}
                placement="left"
                size="xs"
                bg={ThemeColorVariable.Background}
            >
                <SideNavigationHeader onClose={onCloseSideNav} />
                <Box
                    as="aside"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex="1"
                    h="100%"
                    color={ThemeColorVariable.OnBackground}
                >
                    <NavigationList>
                        <SideNavigationMenuButton
                            onClick={openLanguageModal}
                            areaLabel={t("miscChangeLanguage")}
                            icon={Icons.Language}
                            text={t("miscXLanguage", { 0: getNameOfCurrentLang() })}
                        />
                        <SideNavigationMenuButton
                            onClick={togglePrivacyPolicyModal}
                            icon={Icons.File}
                            text={t("privacyPolicy")}
                        />
                        <Box p={ThemeSpaceVariable.Medium}>
                            <Divider />
                        </Box>
                        <Link
                            display="flex"
                            gap={ThemeSpaceVariable.Small}
                            px={ThemeSpaceVariable.Medium}
                            href="https://www.caspeco.se/bokningssystem"
                            isExternal
                        >
                            <PoweredByCaspeco />
                        </Link>
                    </NavigationList>
                </Box>
            </Drawer>
        </>
    );
};

export default SideNavigation;
