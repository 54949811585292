import { RecordFactory } from "old/common/modules/recordFactory";

export interface IMandatoryContactFields {
    city: boolean;
    contactFilter: boolean;
    customerNumber: boolean;
    email: boolean;
    fax: boolean;
    information: boolean;
    invoiceReference: boolean;
    mobile: boolean;
    phone: boolean;
    streetaddress: boolean;
    zipcode: boolean;
}

const MandatoryContactFieldsRecord = RecordFactory<IMandatoryContactFields>({
    customerNumber: false,
    streetaddress: false,
    zipcode: false,
    city: false,
    mobile: false,
    email: false,
    phone: false,
    fax: false,
    invoiceReference: false,
    information: false,
    contactFilter: false,
});

export class MandatoryContactFields
    extends MandatoryContactFieldsRecord
    implements IMandatoryContactFields
{
    constructor(values?: Partial<IMandatoryContactFields>) {
        super(values);
    }
}
