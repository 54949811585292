import { ReduceAction } from "old/framework/actions/action";
import { HttpVerb } from "old/framework/modules/requestHelper";

export class LoadingAction extends ReduceAction {
    public actionType: string;

    constructor(
        verb: HttpVerb,
        endpoint: string,
        requestState: "REQUEST" | "SUCCESS" | "FAIL",
        public params?: string
    ) {
        super();
        const verbStr = HttpVerb[verb].toUpperCase();
        this.actionType = `${verbStr}_${endpoint}_${requestState}`;
        this.doDispatch();
    }
}
