// TODO: Make messageData an immutable Map
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IPropertyWarning {
    propertyName: string;
    message: string;
    messageData: Record<string, any>;
}

const PropertyWarningRecord = RecordFactory<IPropertyWarning>({
    propertyName: "",
    message: "",
    messageData: {},
});

export class PropertyWarning extends PropertyWarningRecord implements IPropertyWarning {
    constructor(values?: Partial<IPropertyWarning>) {
        super(values);
    }
}
