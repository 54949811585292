import Action from "old/framework/actions/action";
import { ArticleStock } from "old/models/articleStock";
import { Map } from "immutable";

export class ArticleStockSuccess extends Action {
    static actionType: string = "ArticleStock_success";

    constructor(_parameters: {}) {
        super(false);
        this.doDispatch();
    }
}

export class ArticleStockFail extends Action {
    static actionType: string = "ArticleStock_fail";

    constructor(_parameters: {}) {
        super(false);
        this.doDispatch();
    }
}

export class LoadByArticleIdSuccess extends Action {
    static actionType: string = "ArticleStock_LoadByArticleIdSuccess";

    constructor(
        public articleIds: number[],
        public articleStocks: Map<number, Map<number, ArticleStock>>
    ) {
        super(false);
        this.doDispatch();
    }
}

export class LoadByArticleIds extends Action {
    static actionType: string = "ArticleStock_LoadByArticleId";

    constructor(public articleIds: number[]) {
        super(false);
        this.doDispatch();
    }
}

export class Create extends Action {
    static actionType: string = "ArticleStock_create";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}
export class Update extends Action {
    static actionType: string = "ArticleStock_update";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}
export class CreateFailed extends Action {
    static actionType: string = "ArticleStock_created_failed";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}
export class UpdateFailed extends Action {
    static actionType: string = "ArticleStock_updated_failed";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}

// ArticleStock sucessfully created (API)
export class CreatedSuccessfully extends Action {
    static actionType: string = "ArticleStock_created_successfully";

    constructor(public temporaryId: number, public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}

export class Delete extends Action {
    static actionType: string = "ArticleStock_delete";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}

export class DeleteFailed extends Action {
    static actionType: string = "ArticleStock_delete_failed";

    constructor(public ArticleStock: ArticleStock) {
        super(false);
        this.doDispatch();
    }
}
