import { WebBookingSection } from "old/models/webBookingSection";
import { RecordFactory } from "old/common/modules/recordFactory";
import { List } from "immutable";

export interface IWebBookingUnit {
    id: number;
    name: string;
    timeZone: string;
    sections: List<WebBookingSection>;
}

const WebBookingUnitRecord = RecordFactory<IWebBookingUnit>({
    id: -1,
    name: "",
    timeZone: "",
    sections: List<WebBookingSection>(),
});

export class WebBookingUnit extends WebBookingUnitRecord implements IWebBookingUnit {
    constructor(values?: Partial<IWebBookingUnit>) {
        if (values && values.sections && !values.sections.set) {
            values.sections = List<WebBookingSection>(
                values.sections.map((m: any) => new WebBookingSection(m))
            );
        }

        super(values);
    }
}
