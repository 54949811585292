import {
    externalBookingSettingsQuery as externalBookingSettingsQueryCreator,
    webBookingQuery as webBookingQueryCreator,
} from "api/apiHooks";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { WebBooking } from "old/models/webBooking";
import queryClient from "query-client";

const queryFunction = async <T>(query: { queryKey: string[]; queryFn: () => Promise<T> }) => {
    let data = queryClient.getQueryData(query.queryKey);

    if (!data) {
        data = await queryClient.fetchQuery(query);
    }
    return data as T;
};

type WebBookingLoaderParams = {
    bookingId: string;
};

type BookingLoaderParams = {
    unitId: number;
};

export const webBookingLoader = async ({ bookingId }: WebBookingLoaderParams) => {
    const webBooking = await queryFunction<WebBooking>(webBookingQueryCreator(bookingId));
    const settings = await queryFunction<ExternalBookingSettings>(
        externalBookingSettingsQueryCreator(webBooking.unitId)
    );
    return { webBooking, settings };
};

export const bookingLoader = async ({ unitId }: BookingLoaderParams) => {
    const settings = await queryFunction<ExternalBookingSettings>(
        externalBookingSettingsQueryCreator(unitId)
    );
    return { settings };
};
