import * as BookingMenuExternalActions from "old/actions/bookingMenuExternalActions";
import * as API from "old/framework/modules/unauthedAPIBase";

import { List } from "immutable";
import { BookingMenuExternal } from "old/models/bookingMenuExternal";

interface IBookingMenuExternalAPI {
    load(id: string, system: string): void;
    respond(bookingMenus: List<BookingMenuExternal>, system: string, respondSilent: boolean): void;
}

class BookingMenuExternalAPI extends API.UnauthedAPIBase implements IBookingMenuExternalAPI {
    constructor() {
        super();
        this.setApiVersion("v1");
    }

    load(bookingMenuId: string, system: string) {
        this.get("/bookingMenu/bookingMenusExternal/{0}", {
            params: [bookingMenuId],
            headers: { System: system },
        })
            .then((result) => {
                const data = result.data;
                new BookingMenuExternalActions.LoadedSuccessfully(new BookingMenuExternal(data));
            })
            .catch((errorObj) => {
                console.error(errorObj);
            });
    }

    // loadSuccess(operation: any) {
    //     new LoadingActions.LoadSuccess(operation);
    // }

    respond(bookingMenus: List<BookingMenuExternal>, system: string, respondSilent: boolean) {
        this.post("bookingMenu/bookingMenusExternal/Respond", {
            data: bookingMenus,
            headers: { System: system },
        })
            .then((result) => {
                const data = result.data;
                const updatedBookingMenus = List<BookingMenuExternal>(
                    data.map((d: any) => new BookingMenuExternal(d))
                );
                new BookingMenuExternalActions.UpdatedSuccessfully(
                    updatedBookingMenus,
                    respondSilent
                );
                //new ToastActions.AddNew({ heading: "Menysvar skickat", type: ToastType.Success });
            })
            .catch((errorObj) => {
                new BookingMenuExternalActions.RespondMenuFailed();
            });
    }
}

const bookingMenuExternalAPI = new BookingMenuExternalAPI();
export default bookingMenuExternalAPI;
