import { validateEmail } from "old/models/customValidators";
import { translate as t } from "old/common/mixins/localeHelper";

class Validators {
    public static isAnything(str: string) {
        try {
            if (str.length > 0) {
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    }

    public static integerType(str: string) {
        if (isNaN(parseInt(str))) {
            return "Ogiltigt nummer";
        } 
            return null;
        
    }

    public static integerTypeRange(min: number, max: number, str: string) {
        const myInt = parseInt(str);
        if (isNaN(myInt)) {
            return "Ogiltigt nummer";
        } if (myInt >= min && myInt <= max) {
            return null;
        } 
            return "Ogiltigt nummer"; //TODO build a translatable message here
        
    }

    public static stringTypeRange(min: number, max: number, str: string) {
        if (str.length >= min && str.length <= max) {
            return null;
        } 
            return "Ogiltigt text"; //TODO build a translatable message here
        
    }

    public static floatType(str: string) {
        const regexp = new RegExp("[^0-9,.]");
        if (regexp.test(str) || isNaN(parseFloat(str))) {
            return "Ogiltigt decimaltal";
        } 
            return null;
        
    }

    public static email(email: string) {
        if (validateEmail(email)) {
            return null;
        } 
            return t("booking.contact.validation.emailInvalid");
        
    }

    public static zipCode(str: string) {
        const regexp = new RegExp("[^0-9 ]");
        if (regexp.test(str)) {
            return "Ogiltig postkod";
        } 
            return null;
        
    }

    /**
     * Taken from https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
     * @param input whole URL
     */
    public static isValidHttpUrl(input: string) {
        let url;

        try {
            url = new URL(input);
        } catch (_) {
            return false;
        }
        return url.protocol === "https:";
    }

    /**
     * 46elks contraints:
     * - Only contains the characters A-Z, a-z and 0-9
     * - Is between 3 and 11 characters long
     * - Doesn't start with a number
     * @param str Alphanumeric sender id
     */
    public static smSsender(str: string) {
        const regexp = new RegExp("^[a-z,A-Z][0-9,a-z,A-Z]{2,10}$");
        if (str === null || !this.isAnything(str) || !regexp.test(str)) {
            return "Ogiltig SMS-avsändare";
        } 
            return null;
        
    }

    /**
     * Shallow check for organization code validity
     * i.e. XXXXXX-XXXX or XXXXXXXXXXX
     */
    public static organizationCode(organizationCode: string) {
        const invalidErrorMessage = "Ogiltigt personnummer";
        const tmpStr = organizationCode.trim();

        if ((tmpStr.substring(6, 7) === "-" && tmpStr.length === 11) || tmpStr.length === 10) {
            return null;
        }

        return invalidErrorMessage;
    }

    public static personalNumber(personalNumber: string) {
        const invalidErrorMessage = "Ogiltigt personnummer";
        let tmpStr;
        tmpStr = personalNumber.trim();

        if (tmpStr.length !== 11) {
            return invalidErrorMessage;
        }

        if (tmpStr.substring(7, 1) !== "-" && tmpStr.substring(7, 1) !== "+") {
            return invalidErrorMessage;
        }

        // let isPlus = false;
        // if (tmpStr.substring(7, 1) === "+") {
        //     isPlus = true;
        // }

        tmpStr = tmpStr.replace(/[1234567890]/g, "");

        if (tmpStr.length !== 10) {
            return invalidErrorMessage;
        }

        //kolla efter specialformatet 000000-XXXX
        if (tmpStr.substring(0, 6) === "000000") {
            return null;
        }
        return null;
        //Valid date? TODO copied from BC, fix
        //try {

        //    var yearPart = parseInt(tmpStr.Substring(0, 2));
        //    var monthPart = parseInt(tmpStr.Substring(2, 2));
        //    var dayPart = parseInt(tmpStr.Substring(4, 2));

        //    yearPart += (Date.now.year \ 100) * 100
        //    If isPlus Then
        //        yearPart -= 100
        //    End If

        //    ' Handle samordningsnummer
        //    If (dayPart > 31) Then
        //        dayPart = dayPart - 60
        //    End If

        //    Dim validDate As Date = New Date(yearPart, monthPart, dayPart)
        //Catch ex As Exception
        //    Return False
        //End Try

        //Dim tmp As Integer
        //tmp = Mid(tmpStr, 3, 2)
        //If tmp < 1 Or tmp > 12 Then
        //    Return False
        //End If

        //tmp = Mid(tmpStr, 5, 2)
        //If tmp < 1 Or tmp > 91 Or (tmp > 31 And tmp < 61) Then
        //    Return False
        //End If

        //If Not validateModulo10(tmpStr) Then
        //    Return False
        //End If

        //Return True
    }
}

export default Validators;
