import { WebBookingContact } from "old/models/webBookingContact";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { RecordFactory } from "old/common/modules/recordFactory";

export enum PaymentMethodType {
    CreditCard = 0,
    Invoice = 1,
    Mobile = 6,
    Undefined = 999,
}

export interface IWebBookingPaymentTerminalParameters {
    terminalReturnUrl: string;
    contact: WebBookingContact | null;
    customer: WebBookingCustomer | null;
    message: string;
    payMethod: PaymentMethodType;
}

const WebBookingPaymentTerminalParametersRecord =
    RecordFactory<IWebBookingPaymentTerminalParameters>({
        terminalReturnUrl: "",
        contact: null,
        message: "",
        payMethod: PaymentMethodType.CreditCard,
        customer: null,
    });

export class WebBookingPaymentTerminalParameters
    extends WebBookingPaymentTerminalParametersRecord
    implements IWebBookingPaymentTerminalParameters
{
    constructor(values?: Partial<IWebBookingPaymentTerminalParameters>) {
        super(values);
    }
}
