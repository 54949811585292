import Validators from "old/modules/validators";

import { ContactFilterData } from "old/models/contactFilterData";
import { Customer } from "old/models/customer";
import { IMandatoryContactFields, MandatoryContactFields } from "old/models/mandatoryContactFields";
import { List } from "immutable";
import { RecordFactory } from "old/common/modules/recordFactory";
import { translate as t } from "old/common/mixins/localeHelper";
import { ValidationResult } from "old/common/models/validation/validationResult";

export enum ContactFlags {
    None = 0,
    AllowInvoicing = 1 << 0,
    ElectronicInvoice = 1 << 1,
    HasBeenExported = 1 << 2,
}

export interface IContact {
    allowStoringForever: boolean;
    binaryFlags: ContactFlags;
    city: string;
    contactFilterData: List<ContactFilterData>;
    customer: Customer;
    customerId: number;
    customerIds: List<number>;
    customerName: string;
    customerNumber: string;
    email: string;
    fax: string;
    fname: string;
    hasWarning: boolean;
    hidden: number;
    id: number;
    information: string;
    invoiceReference: string;
    invoicingFee?: number;
    invoicingPaymentTerms?: number;
    isAnonymized: boolean;
    lastGrab: boolean;
    lname: string;
    mobile: string;
    newsletter: boolean;
    phone: string;
    returning: boolean;
    streetaddress: string;
    tempId: number;
    warningText: string;
    zipcode: string;
}

const ContactRecord = RecordFactory<IContact>({
    allowStoringForever: false,
    binaryFlags: ContactFlags.None,
    city: "",
    contactFilterData: List<ContactFilterData>(),
    customer: null,
    customerId: 0,
    customerIds: null,
    customerName: "",
    customerNumber: "",
    email: "",
    fax: "",
    fname: "",
    hasWarning: false,
    hidden: 0,
    id: -1,
    information: "",
    invoiceReference: "",
    invoicingFee: null,
    invoicingPaymentTerms: null,
    isAnonymized: false,
    lastGrab: false,
    lname: "",
    mobile: "",
    newsletter: false,
    phone: "",
    returning: false,
    streetaddress: "",
    tempId: -1,
    warningText: "",
    zipcode: "",
});

export function isRequired(
    fieldName: keyof IMandatoryContactFields,
    mandatoryContactFields: MandatoryContactFields = new MandatoryContactFields()
): boolean {
    return mandatoryContactFields && mandatoryContactFields.has(fieldName)
        ? mandatoryContactFields.get(fieldName)
        : false;
}

const warningFilterId = 100;

export class Contact extends ContactRecord implements IContact {
    constructor(values: Partial<IContact> = {}) {
        if (!(<any>values).set) {
            if (values.isAnonymized) {
                values.fname = t("booking.contact.isAnonymized");
            }
            // Do not update if values is already immutable record (e.g. on Walk-in).
            if (values.contactFilterData) {
                values.contactFilterData = List(
                    values.contactFilterData.map(
                        (filterData: any) => new ContactFilterData(filterData)
                    )
                );
            }
            if (Contact.valuesHasWarning(values)) {
                const warnings = Contact.getInternalMessageWarningsFromValues(values);
                if (warnings && warnings.length > 0) {
                    values.warningText = warnings.join(", ");
                    values.hasWarning = true;
                }
            } else {
                values.hasWarning = false;
            }
        }

        super(values);
    }

    hasTempId(): boolean {
        return this.tempId > 0;
    }

    getWarnings(): List<ContactFilterData> {
        if (this.contactFilterData) {
            return this.contactFilterData
                .filter((filterData) => filterData.contactFilterId === warningFilterId)
                .toList();
        } 
            return List<ContactFilterData>();
        
    }

    getInternalMessageWarnings(): string[] {
        const warnings: string[] = [];
        this.getWarnings().forEach((warning) => {
            if (warning.selectedStringItems && warning.selectedStringItems.count() > 0) {
                warning.selectedStringItems.forEach((item) => {
                    if (item && item.toUpperCase() !== "INGET") {
                        warnings.push(item);
                    }
                });
            }
        });

        return warnings;
    }

    static getWarningsFromValues(values: Partial<IContact>): List<ContactFilterData> {
        if (values && values.contactFilterData) {
            return values.contactFilterData
                .filter((filterData) => filterData.contactFilterId === warningFilterId)
                .toList();
        } 
            return List<ContactFilterData>();
        
    }

    static valuesHasWarning(values: Partial<IContact>): boolean {
        return (
            values.contactFilterData &&
            values.contactFilterData.some(
                (filterData) => filterData.contactFilterId === warningFilterId
            )
        );
    }

    static getInternalMessageWarningsFromValues(values: Partial<IContact>): string[] {
        const warnings: string[] = [];
        Contact.getWarningsFromValues(values).forEach((warning) => {
            if (warning.selectedStringItems && warning.selectedStringItems.count() > 0) {
                warning.selectedStringItems.forEach((item) => {
                    if (item && item.toUpperCase() !== "INGET") {
                        warnings.push(item);
                    }
                });
            }
        });

        return warnings;
    }
}

const validatePropertyErrors = (contact: Contact, propName: keyof IContact): string | null => {
    switch (propName) {
        case "fname":
            if (contact.fname.length < 2) {
                return "Förnamn måste innehålla minst två tecken";
            }
            break;
        case "email":
            if (Validators.isAnything(contact.email)) {
                return Validators.email(contact.email);
            }
            break;
        case "zipcode":
            if (Validators.isAnything(contact.zipcode)) {
                return Validators.zipCode(contact.zipcode);
            }
            break;
        default:
            return null;
    }
    return null;
};

export function validate(
    contact: Contact,
    errors: boolean = true,
    warnings: boolean = true,
    mandatoryFields: MandatoryContactFields = new MandatoryContactFields()
): ValidationResult {
    let validationResult = new ValidationResult();
    let message: string;
    let mandatoryError = false;

    contact.forEach((value: string, key: keyof IContact) => {
        if (errors) {
            message = validatePropertyErrors(contact, key);
            if (message !== null) {
                validationResult = validationResult.addPropertyError(key, message);
            } else if (
                isRequired(<keyof IMandatoryContactFields>key, mandatoryFields) &&
                value === "" &&
                !mandatoryError
            ) {
                mandatoryError = true;
                validationResult = validationResult.addGeneralError(
                    t("booking.contact.validation.missingMandatoryField")
                );
            }
        }
    });

    return validationResult;
}
