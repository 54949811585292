import Validators from "old/modules/validators";

import {
    IMandatoryCustomerFields,
    MandatoryCustomerFields,
} from "old/models/mandatoryCustomerFields";
import { RecordFactory } from "old/common/modules/recordFactory";
import { ValidationResult } from "old/common/models/validation/validationResult";

export enum CustomerFlags {
    None = 0,
    AllowInvoicing = 1, // 1 << 0
    CoInvoicing = 2, // 1 << 1
    ElectronicInvoice = 4,
    InternalInvoicing = 1 << 3,
}

export interface ICustomer {
    binaryFlags: CustomerFlags;
    city: string;
    customerNumber: string;
    email: string;
    id: number;
    information: string;
    invoiceCity: string;
    invoiceCode1: string;
    invoiceCode2: string;
    invoiceCode3: string;
    invoiceReference: string;
    invoiceStreetaddress: string;
    invoiceZipCode: string;
    invoicingFee?: number;
    invoicingPaymentTerms?: number;
    marking: string;
    name: string;
    orgNr: string;
    phone: string;
    streetaddress: string;
    zipcode: string;
    hidden: boolean;
}

const CustomerRecord = RecordFactory<ICustomer>({
    binaryFlags: CustomerFlags.None,
    city: "",
    customerNumber: "",
    email: "",
    id: 0,
    information: "",
    invoiceCity: "",
    invoiceCode1: "",
    invoiceCode2: "",
    invoiceCode3: "",
    invoiceReference: "",
    invoiceStreetaddress: "",
    invoiceZipCode: "",
    invoicingFee: null,
    invoicingPaymentTerms: null,
    marking: "",
    name: "",
    orgNr: "",
    phone: "",
    streetaddress: "",
    zipcode: "",
    hidden: false,
});

export class Customer extends CustomerRecord implements ICustomer {
    constructor(values?: Partial<ICustomer>) {
        super(values);
    }
}

export function isRequired(
    fieldName: keyof IMandatoryCustomerFields,
    mandatoryCustomerFields: MandatoryCustomerFields = new MandatoryCustomerFields()
): boolean {
    return mandatoryCustomerFields && mandatoryCustomerFields.has(fieldName)
        ? mandatoryCustomerFields.get(fieldName)
        : false;
}

const validatePropertyErrors = (customer: Customer, propName: string): string | null => {
    switch (propName) {
        case "name":
            if (customer.name.length < 1) {
                return "Namn får inte lämnas tomt";
            }
            break;
        case "email":
            if (Validators.isAnything(customer.email)) {
                return Validators.email(customer.email);
            }
            break;
        case "zipcode":
            if (Validators.isAnything(customer.zipcode)) {
                return Validators.zipCode(customer.zipcode);
            }
            break;
        default:
            return null;
    }
    return null;
};

const validatePropertyWarnings = (customer: Customer, propName: string): string => {
    return null;
};

export function validate(
    customer: Customer,
    errors: boolean = true,
    warnings: boolean = true,
    mandatoryFields: MandatoryCustomerFields = new MandatoryCustomerFields()
): ValidationResult {
    let validationResult = new ValidationResult();
    let message;
    let mandatoryError = false;

    customer.forEach((value: any, property: any) => {
        if (errors) {
            message = validatePropertyErrors(customer, property);
            if (message !== null) {
                validationResult = validationResult.addPropertyError(property, message);
            } else if (
                isRequired(<keyof IMandatoryCustomerFields>property, mandatoryFields) &&
                value === "" &&
                !mandatoryError
            ) {
                mandatoryError = true;
                validationResult = validationResult.addGeneralError(
                    "Alla obligatoriska fält är inte ifyllda"
                );
            }
        }

        if (warnings) {
            message = validatePropertyWarnings(customer, property);
            if (message !== null) {
                validationResult = validationResult.addPropertyWarning(property, message);
            }
        }
    });

    return validationResult;
}
