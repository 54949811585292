import { ArticleStock } from "old/models/articleStock";
import WebFlags from "old/models/bookingMenuExternalItemWebFlagsEnum";
import { RecordFactory } from "old/common/modules/recordFactory";
import { Record } from "immutable";
import moment from "moment-timezone";

export interface IBookingMenuExternalItem {
    name: string;
    description: string;
    articleID: number;
    quantity: number;
    price: number;
    time: IMoment;
    webFlags: WebFlags;
    inactivatedUntil: IMoment;
    wasOutOfStock: boolean;
}

const bookingMenuExternalItemRecord = RecordFactory<IBookingMenuExternalItem>({
    name: "",
    description: "",
    articleID: -1,
    quantity: -1,
    price: -1,
    time: moment.invalid(),
    webFlags: null,
    inactivatedUntil: moment.invalid(),
    wasOutOfStock: false,
});

export class BookingMenuExternalItem
    extends bookingMenuExternalItemRecord
    implements IBookingMenuExternalItem
{
    constructor(values: Partial<IBookingMenuExternalItem>) {
        if (values && !(values instanceof Record)) {
            if (values.time) {
                values.time = moment(values.time);
            }
            if (values.inactivatedUntil) {
                values.inactivatedUntil = moment(values.inactivatedUntil);
            }
        }
        super(values);
    }

    outOfStock(articleStock: ArticleStock): boolean {
        return this.inactivatedUntil > this.time || (articleStock && articleStock.quantity <= 0);
    }
}
