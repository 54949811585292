import moment from "moment-timezone";

import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingCreateParametersRuleTime {
    ruleId: number;
    start: IMoment;
    availableUntil: IMoment;
}

const WebBookingCreateParametersRuleTimeRecord = RecordFactory<IWebBookingCreateParametersRuleTime>(
    {
        ruleId: -1,
        start: moment.invalid(),
        availableUntil: moment.invalid(),
    }
);

export class WebBookingCreateParametersRuleTime
    extends WebBookingCreateParametersRuleTimeRecord
    implements IWebBookingCreateParametersRuleTime
{
    constructor(values: Partial<IWebBookingCreateParametersRuleTime>) {
        super(values);
    }
}

export function Record(values: any): WebBookingCreateParametersRuleTime {
    return new WebBookingCreateParametersRuleTime(values);
}
