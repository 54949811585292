import { Article } from "old/models/article";
import { BoundingBox } from "old/models/boundingBox";
import { IArticleInterface } from "./ArticleInterface";
import { List, Set } from "immutable";
import { RecordFactory } from "old/common/modules/recordFactory";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { ArticleGroupType } from "old/stores/articleGroupStore";

export enum TableMapItemType {
    WALL,
    DOOR,
    WINDOW,
    TABLE_RECT,
    TABLE_ELLIPSE,
    LABEL,
    VIRTUAL,
}

export interface ITable extends IArticleInterface {
    article: Article | null;
    articleGroupId: number;
    articleGroupType: ArticleGroupType;
    articleId: number;
    articleIds: string;
    articleIdsSet: Set<number>;
    boundingBox: BoundingBox | null;
    chairs: number;
    chairsMax: number;
    departmentId: string;
    doRemove: boolean;
    guestsMin: number;
    id: number;
    includedInResourcePool: boolean;
    isResourcePool: boolean;
    itemType: TableMapItemType;
    positionX: number;
    positionY: number;
    sizeX: number;
    sizeY: number;
    tableName: string;
    tableNumber: number;
    sortOrder: number;
    stockBalance: number;
    unitId: number;
    sortCustom: number;
    sectionId: number;
    templateId: number;
    webBookable: boolean;
    priority: number;
    rotate: number;
}

const TableRecord = RecordFactory<ITable>({
    id: -1,
    article: null,
    articleId: -1,
    articleGroupId: -1,
    articleGroupType: -1,
    stockBalance: 0,
    includedInResourcePool: false,
    departmentId: "",
    sortOrder: -1,
    webBookable: false,
    isResourcePool: false,
    chairs: -1,
    chairsMax: -1,
    guestsMin: -1,
    priority: 0,
    itemType: -1,
    positionX: -1,
    positionY: -1,
    sizeX: -1,
    sizeY: -1,
    tableName: "",
    tableNumber: -1,
    articleIds: "",
    articleIdsSet: Set<number>(),
    unitId: -1,
    sortCustom: -1,
    sectionId: -1,
    templateId: -1,
    boundingBox: null,
    doRemove: false,
    name: "",
    rotate: 0,
});

export class Table extends TableRecord implements ITable {
    constructor(values: Partial<ITable>) {
        if (values && values.articleIds && values.articleIds.length > 0) {
            values.articleIdsSet = List<number>(
                values.articleIds.split(",").map((articleId: string) => parseInt(articleId, 10))
            ).toSet();
        }

        if (values.article && !values.article.set) values.article = new Article(values.article);

        if (values.itemType === TableMapItemType.WALL) {
            // sizeX and sizeY represents x2 and y2 for walls in db...
            values.sizeX! -= values.positionX!;
            values.sizeY! -= values.positionY!;
        }

        if (!values.boundingBox) {
            values.boundingBox = getBoundingBox(values);
        }

        super(values);
    }

    isBookable(): boolean {
        return (
            this.itemType === TableMapItemType.TABLE_RECT ||
            this.itemType === TableMapItemType.TABLE_ELLIPSE
        );
    }
}

export function getMinMax(tables: List<Table>): {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
} {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    tables
        .filter((t) => t.itemType !== TableMapItemType.VIRTUAL)
        .forEach((table) => {
            const x2 = table.positionX + table.sizeX;
            minX = Math.min(minX, x2, table.positionX);
            maxX = Math.max(maxX, x2, table.positionX);

            const y2 = table.positionY + table.sizeY;
            minY = Math.min(minY, y2, table.positionY);
            maxY = Math.max(maxY, y2, table.positionY);
        });

    return {
        minX,
        minY,
        maxX,
        maxY,
    };
}

export function move(table: Table, xAxis: number, yAxis: number): Table {
    table = table.set("positionX", table.positionX + xAxis);
    table = table.set("positionY", table.positionY + yAxis);
    return table;
}

export function rotate(table: Table): Table {
    const posX = table.positionX;
    const sizeX = table.sizeX;
    if (table.itemType === TableMapItemType.WALL) {
        table = table.set("positionX", -table.positionY);
        table = table.set("positionY", posX);
        table = table.set("sizeX", -table.sizeY);
        table = table.set("sizeY", sizeX);
    } else if (
        table.itemType === TableMapItemType.TABLE_RECT ||
        table.itemType === TableMapItemType.TABLE_ELLIPSE ||
        table.itemType === TableMapItemType.LABEL
    ) {
        table = table.set("positionX", -table.positionY - table.sizeY);
        table = table.set("positionY", posX);
        table = table.set("sizeX", table.sizeY);
        table = table.set("sizeY", sizeX);
    }
    return table;
}

export function getBoundingBox(table: Partial<ITable>): BoundingBox {
    const x1 = table.positionX!;
    const y1 = table.positionY!;
    const x2 = table.positionX! + table.sizeX!;
    const y2 = table.positionY! + table.sizeY!;

    const xMin = Math.min(x1, x2);
    const xMax = Math.max(x1, x2);
    const yMin = Math.min(y1, y2);
    const yMax = Math.max(y1, y2);

    const width = xMax - xMin;
    const height = yMax - yMin;

    return new BoundingBox({
        xMin,
        xMax,
        yMin,
        yMax,
        width,
        height,
    });
}

export function validateList(tables: List<Table>): ValidationResult {
    let validationResult = new ValidationResult();
    tables.forEach(
        (table) =>
            (validationResult = validationResult.mergeValidationResult(
                validate(table, { tableName: ` - ${  table.tableName}` })
            ))
    );
    return validationResult;
}

export function validate(table: Table, messageData?: any): ValidationResult {
    let validationResult = new ValidationResult();
    if (!messageData) messageData = { tableName: "" };
    if (table.doRemove) return validationResult;

    if (table) {
        Object.keys(table).forEach((property) => {
            const errorMessage = validatePropertyForError(table, property);
            if (errorMessage !== null) {
                validationResult = validationResult.addPropertyError(property, errorMessage);
            }
        });
        //Disabled, used to force webbooking to only book virtual tables
        // if (table.guestsMin > table.chairs) {
        //   validationResult = validationResult.addPropertyError(
        //     "guestsMin",
        //     "booking.tableLayout.validation.guestsMinChairs", messageData
        //   );
        // }

        if (table.articleId > 0 && table.article) {
            const tableNameSplit = table.tableName.split(" ");
            tableNameSplit.forEach((textSegment) => {
                const tableNumber = parseInt(textSegment);
                if (!isNaN(tableNumber) && !table.article?.name.includes(textSegment)) {
                    validationResult = validationResult.addPropertyError(
                        "tableName",
                        "booking.tableLayout.validation.tableNameNotInArticleNameRow1",
                        messageData
                    );
                    validationResult = validationResult.addPropertyError(
                        "tableName",
                        "booking.tableLayout.validation.tableNameNotInArticleNameRow2"
                    );
                }
            });
        }

        if (table.rotate > 179 || table.rotate < -179) {
            validationResult = validationResult.addPropertyError(
                "rotate",
                "booking.tableLayout.validation.invalidRotation",
                messageData
            );
        }

        if (
            table.sectionId < 1 &&
            (table.itemType === TableMapItemType.TABLE_ELLIPSE ||
                table.itemType === TableMapItemType.TABLE_RECT ||
                table.itemType === TableMapItemType.VIRTUAL)
        ) {
            validationResult = validationResult.addPropertyError(
                "section",
                "booking.tableLayout.validation.sectionMissing",
                messageData
            );
        }

        if (table.id < 0) {
            //Inside tableLayoutWizard
            if (table.article) {
                if (table.article.articleGroupId < 0) {
                    validationResult = validationResult.addPropertyError(
                        "articleGroupId",
                        "booking.tableLayout.validation.articleGroupMissing",
                        messageData
                    );
                }
                if (table.article.departmentIds.isEmpty()) {
                    validationResult = validationResult.addPropertyWarning(
                        "departments",
                        "booking.tableLayout.validation.departmentsMissing",
                        messageData
                    );
                }
            } else {
                if (table.departmentId.length < 1 && table.articleGroupId > 0) {
                    validationResult = validationResult.addPropertyWarning(
                        "departments",
                        "booking.tableLayout.validation.departmentsMissing",
                        messageData
                    );
                }
            }
        }
    }

    return validationResult;
}

const validatePropertyForError = (item: Table, propName: string): string | null => {
    const validationError = `booking.tableLayout.validation.${  propName}`;
    switch (propName) {
        case "chairs":
            if (item.chairs < 1) {
                return validationError;
            }
            break;
        case "tableName":
            if (!item.tableName || item.tableName.length < 1) {
                return validationError;
            }
            break;
        case "chairsMax":
            if (item.chairsMax < 1) {
                return validationError;
            }
            break;
        case "guestsMin":
            if (item.guestsMin < 0) {
                return validationError;
            }
            break;
        case "sectionId":
            if (item.sectionId < 0) {
                return validationError;
            }
            break;
        default:
    }
    return null;
};

export interface ITableInfo {
    tableId: number;
    articleId: number;
    tableName: string;
    unitId: number;
    templateId: number;
    templateName: string;
    sectionId: number;
}

const TableInfoRecord = RecordFactory<ITableInfo>({
    tableId: -1,
    articleId: -1,
    tableName: "",
    unitId: -1,
    templateId: -1,
    templateName: "",
    sectionId: -1,
});

export class TableInfo extends TableInfoRecord implements ITableInfo {
    constructor(values?: Partial<ITableInfo>) {
        super(values);
    }
}
