import { IStaticallyTypedRecord, RecordFactory } from "old/common/modules/recordFactory";
import { Set } from "immutable";
import moment from "moment-timezone";

/**
 * @export
 * @interface IArticleStock
 */

export enum ArticleStockType {
    Undefined = 0,
    RollingRefill = 1,
    Refill = 2,
    IncomingDelivery = 3,
    Sale = 4,
    Stocktaking = 5,
    ManualChange = 6,
    CurrentValue = 7,
    ChunkedManualChange = 8,
    Leftovers = 9,
}

export enum ReferenceType {
    Undefined = 0,
    BookingSale = 1,
    CheckoutSale = 2,
    AirSync = 3,
}
export interface IArticleStock {
    id: number;
    articleId: number;
    unitId: number;
    quantity: number;
    refillDayNumbers: Set<number>;
    durabilityHours: number;
    refillTime: number;
    durableTo: IMoment;
    type: ArticleStockType;
    validityDate: IMoment;
    affectsRuleId: number;
    comment: string;
    referenceId: string;
    referenceType: ReferenceType;
    createdDate: IMoment;
    createdBy: string;
    info: string;
}

const ArticleStockRecord = RecordFactory<IArticleStock>({
    id: -1,
    articleId: -1,
    unitId: -1,
    quantity: 0,
    refillDayNumbers: Set<number>(),
    durabilityHours: 0,
    refillTime: 0,
    durableTo: moment.invalid(),
    type: ArticleStockType.Undefined,
    validityDate: moment.invalid(),
    affectsRuleId: -1,
    comment: "",
    referenceId: "",
    referenceType: ReferenceType.Undefined,
    createdDate: moment.invalid(),
    createdBy: "",
    info: "",
});

let newTempId = -1;

export class ArticleStock extends ArticleStockRecord implements IArticleStock {
    constructor(values: Partial<IArticleStock>) {
        if (values) {
            if (!values.id) values.id = newTempId--;
            values.refillDayNumbers = values.refillDayNumbers
                ? Set(values.refillDayNumbers)
                : Set();
        }
        super(values);
    }

    public getDaysString(): string {
        return this.refillDayNumbers.map((d: number) => moment().day(d).format("dd")).join(", ");
    }
}

export function validatePropertyForError(
    item: IStaticallyTypedRecord<IArticleStock>,
    propName: keyof IArticleStock
): string | null {
    switch (propName) {
        case "quantity":
            if (item.get(propName) < 1 && item.get("type") === ArticleStockType.RollingRefill) {
                return "articleStock.validation.quantity";
            }
            break;
        default:
            return null;
    }
    return null;
}
