import { RAYGUN_API_KEY } from "logging/raygunConfig";
import rg4js from "raygun4js";

function setRaygunTags(systemName: string, isLive: boolean, uiDeployment: string) {
    const tags = [`system:${systemName}`, `isLive:${isLive ? "yes" : "no"}`, `ui:${uiDeployment}`];

    try {
        rg4js("withTags", tags);
    } catch (error) {
        console.log("Unable to set Raygun tags!", error);
    }
}

interface LogOptions {
    tags?: string[];
    customData?: unknown;
    message?: string;
}

export function logDebug(options?: LogOptions) {
    if (options?.tags) {
        rg4js("withTags", options.tags);
    }
    if (options?.customData) {
        rg4js("withCustomData", options.customData);
    }
}

export function logWarn(options?: LogOptions) {
    if (options?.tags) {
        rg4js("withTags", options.tags);
    }
    if (options?.customData) {
        rg4js("withCustomData", options.customData);
    }
}

type ErrorWithMessage = Error & { message: string; stack: string };

export function logError(error: ErrorWithMessage, options?: LogOptions) {
    const customData = {
        error,
        message: error.message,
        stack: error.stack,
    };
    if (options?.tags) {
        rg4js("withTags", options.tags);
    }
    if (options?.customData) {
        rg4js("withCustomData", options.customData);
    }

    rg4js("send", {
        error: new Error(`Cannot read properties of undefined: ${customData.error}`),
    });
}

export const raygunApiKey = RAYGUN_API_KEY;
export { setRaygunTags };
