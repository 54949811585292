import { List } from "immutable";
import moment from "moment-timezone";

import WebTimeRuleBookingTypeEnum from "old/models/bookingTypeOption";
import ConditionRuleEnum from "old/models/webRuleConditionRuleEnum";
import ConditionRulePaymentEnum from "old/models/webRuleConditionRulePaymentEnum";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingTimeRule {
    id: number;
    title: string;
    conditions: string;
    paymentInformation: string;
    maximumConcurrentBookings: number;
    maximumConcurrentSeats: number;
    maxGroupSize: number;
    minGroupSize: number;
    lengthMinutes: number;
    recoupTime: number;
    isClosed: boolean;
    validityDate: IMoment;
    date: IMoment;
    arenaEventId: number;
    arenaCrmGroupId: number;
    times: List<WebBookingTimeRuleUnit>;
    menuGuid: string;
    requireMenuSelection: boolean;
    requireMenuPayment: boolean;
    payment: ConditionRulePaymentEnum;
    paymentModifier: ConditionRuleEnum;
    paymentAmount: number;
    paymentVAT: number;
    isTextRow: boolean;
    comment: string;
    sectionId: number;
    bookingType: WebTimeRuleBookingTypeEnum;
    timeZone: string;
    forceDeliveryInfo: boolean;
}

const WebBookingTimeRuleRecord = RecordFactory<IWebBookingTimeRule>({
    id: -1,
    title: "",
    conditions: "",
    paymentInformation: "",
    minGroupSize: null,
    maxGroupSize: null,
    maximumConcurrentBookings: -1,
    maximumConcurrentSeats: -1,
    lengthMinutes: -1,
    recoupTime: -1,
    isClosed: false,
    date: moment.invalid(),
    validityDate: moment.invalid(),
    arenaEventId: -1,
    arenaCrmGroupId: -1,
    times: List<WebBookingTimeRuleUnit>(),
    menuGuid: null,
    requireMenuSelection: false,
    requireMenuPayment: false,
    payment: ConditionRulePaymentEnum.NoFee,
    paymentModifier: ConditionRuleEnum.NoPayment,
    paymentAmount: 0,
    paymentVAT: 0,
    isTextRow: false,
    comment: null,
    sectionId: -1,
    bookingType: 0,
    timeZone: "",
    forceDeliveryInfo: false,
});

export class WebBookingTimeRule extends WebBookingTimeRuleRecord implements IWebBookingTimeRule {
    constructor(values: Partial<IWebBookingTimeRule>) {
        if (values.date) {
            values.date = moment(values.date);
        }

        if (values.times && !values.times.set) {
            values.times.forEach((t: any) => {
                if (!t.ruleId) {
                    t.ruleId = values.id;
                }
            });

            const hasMenu = values.menuGuid && values.menuGuid.length > 10;
            values.times = List<WebBookingTimeRuleUnit>(
                values.times.map((m: any) => {
                    let x = new WebBookingTimeRuleUnit(m);

                    x = x.withMutations((mutable) => {
                        return mutable
                            .set("hasMenu", hasMenu)
                            .set("requireMenuSelection", values.requireMenuSelection)
                            .set("requireMenuPayment", values.requireMenuPayment)
                            .set("isTextRow", values.isTextRow)
                            .set("payment", values.payment)
                            .set("paymentModifier", values.paymentModifier)
                            .set("paymentAmount", values.payment)
                            .set("paymentVAT", values.payment)
                            .set("sectionId", values.sectionId);
                    });
                    return x;
                })
            );
        }

        super(values);
    }
}
