enum BookingMenuExternalGroupType {
    ViewOnly = 0,
    SingleChoice = 1,
    AmountChoice = 2,
    LimitedChoices = 3,
    LimitedMinChoicesToGuests = 4,
    LimitedMinMaxChoicesToGuests = 5,
    LimitedToGuests = 6,
    LimitedToGuestsAndChoices = 7,
    MultipleChoices = 8,
}
export default BookingMenuExternalGroupType;

/// <summary>
/// The ways a group displays itself and has conditions (copied 0-5 from legacy menu in BC)
/// ViewOnly = 0, (Endast visa upp, inget skall fyllas i/boka endast via telefon)
/// SingleChoice = 1, (Oklart, används inte i BC? Kanske bara konferensbokning? Gissningsvis välja exakt ett alternativ, men som ersatts av LimitedChoices)
/// AmountChoice = 2, (Välja fritt)
/// LimitedChoices = 3, (Som mest boka X olika typer av artiklar)
/// LimitedMinChoicesToGuests = 4, (Måste boka för samtliga gäster)
/// LimitedMinMaxChoicesToGuests = 5, (Måste boka för samtliga gäster, som mest boka X olika typer av artiklar)
/// </summary>
