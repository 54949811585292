import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWebBookingCancelParameters {
    disableAllConfirmationMessages: boolean;
    disableEmailConfirmationMessage: boolean;
    disableSmsConfirmationMessage: boolean;
    logMessage: string;
}

const WebBookingCancelParametersRecord = RecordFactory<IWebBookingCancelParameters>({
    disableAllConfirmationMessages: false,
    disableEmailConfirmationMessage: false,
    disableSmsConfirmationMessage: false,
    logMessage: "",
});

export class WebBookingCancelParameters
    extends WebBookingCancelParametersRecord
    implements IWebBookingCancelParameters
{
    constructor(values?: Partial<IWebBookingCancelParameters>) {
        super(values);
    }
}
