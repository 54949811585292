import { WebBookingTimeRule } from "old/models/webBookingTimeRule";

import { RecordFactory } from "old/common/modules/recordFactory";
import { List } from "immutable";

export interface IWebBookingSection {
    id: number;
    name: string;
    timeSets: List<WebBookingTimeRule>;
    hidden: boolean;
    sortOrder: number;
}

const WebBookingSectionRecord = RecordFactory<IWebBookingSection>({
    id: -1,
    name: "",
    timeSets: List<WebBookingTimeRule>(),
    hidden: false,
    sortOrder: 0,
});

export class WebBookingSection extends WebBookingSectionRecord implements IWebBookingSection {
    constructor(values?: Partial<IWebBookingSection>) {
        if (values && values.timeSets && !values.timeSets.set) {
            values.timeSets = List<WebBookingTimeRule>(
                values.timeSets.map((tr) => {
                    if (!tr.set) {
                        (<any>tr).sectionId = values.id;
                    }

                    return new WebBookingTimeRule(tr);
                })
            );
        }

        super(values);
    }
}
