import { Image, Text } from "caspeco-casper-ui";
import CaspecoIcon from "assets/caspecoIcon.svg";

export function PoweredByCaspeco() {
    return (
        <>
            <Image src={CaspecoIcon} />
            <Text>Powered by Caspeco</Text>
        </>
    );
}
