import { hasUnsupportedCharacters } from "helpers/validation-helper";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";

import {
    IMandatoryWebBookingContactFields,
    MandatoryWebBookingContactFields,
} from "old/models/mandatoryWebBookingContactFields";
import Validators from "old/modules/validators";
import { Country } from "react-phone-number-input";

export interface IWebBookingContact {
    name: string;
    fname: string;
    lname: string;
    phone: string;
    mobileCountryCode: Country;
    mobile: string;
    email: string;
    streetaddress: string;
    zipcode: string;
    city: string;
    allowStoringForever: boolean;
    lastGrab: boolean;
    newsletter: boolean;
    deliveryInfo: string;
    customerName: string;
    invoice: boolean;
    organizationCode: string;
    invoiceStreetAddress: string;
    invoiceEmail: string;
    invoiceMarking: string;
}

const WebBookingContactRecord = RecordFactory<IWebBookingContact>({
    name: "",
    fname: "",
    lname: "",
    phone: "",
    mobileCountryCode: "SE",
    mobile: "",
    email: "",
    streetaddress: "",
    zipcode: "",
    city: "",
    allowStoringForever: false,
    lastGrab: false,
    newsletter: false,
    deliveryInfo: "",
    customerName: "",
    invoice: false,
    organizationCode: "",
    invoiceStreetAddress: "",
    invoiceEmail: "",
    invoiceMarking: "",
});

export class WebBookingContact extends WebBookingContactRecord implements IWebBookingContact {
    constructor(values: Partial<IWebBookingContact> = {}) {
        super(values);
    }

    public validate(
        mandatoryWebBookingContactFields: MandatoryWebBookingContactFields,
        errors: boolean = true,
        warnings: boolean = true
    ): ValidationResult {
        let validationResult = new ValidationResult();

        if (errors) {
            validationResult = this.validateErrors(
                validationResult,
                mandatoryWebBookingContactFields
            );
        }

        if (warnings) {
            validationResult = this.validateWarnings(validationResult);
        }

        return validationResult;
    }

    public validatePropertyForUnsupportedCharacters(
        validationResult: ValidationResult,
        propertyName: string,
        propValue: string
    ) {
        if (hasUnsupportedCharacters(propValue)) {
            validationResult = validationResult.addPropertyError(
                propertyName,
                `contact.validation.${propertyName}UnsupportedCharacters`
            );
            validationResult = validationResult.addGeneralError(
                `contact.validation.${propertyName}UnsupportedCharacters`
            );
        }
        return validationResult;
    }

    private validateErrors(
        validationResult: ValidationResult,
        mandatoryWebBookingContactFields: MandatoryWebBookingContactFields
    ): ValidationResult {
        const propertiesToValidate = ["fname", "lname"];

        propertiesToValidate.forEach((propertyName) => {
            validationResult = this.validatePropertyForUnsupportedCharacters(
                validationResult,
                propertyName,
                this[propertyName]
            );
        });

        if (
            this.isRequired("streetaddress", mandatoryWebBookingContactFields) &&
            (this.streetaddress == null || this.streetaddress === "")
        ) {
            validationResult = validationResult.addPropertyError(
                "streetaddress",
                "webBooking.contact.validation.streetaddress"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.contact.validation.streetaddress"
            );
        }

        if (
            this.isRequired("zipcode", mandatoryWebBookingContactFields) &&
            (this.zipcode == null || this.zipcode === "")
        ) {
            validationResult = validationResult.addPropertyError(
                "zipcode",
                "webBooking.contact.validation.zipcode"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.contact.validation.zipcode"
            );
        }

        if (
            this.isRequired("city", mandatoryWebBookingContactFields) &&
            (this.city == null || this.city === "")
        ) {
            validationResult = validationResult.addPropertyError(
                "city",
                "webBooking.contact.validation.city"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.contact.validation.city"
            );
        }

        if (
            this.isRequired("deliveryInfo", mandatoryWebBookingContactFields) &&
            (this.deliveryInfo == null || this.deliveryInfo === "" || this.deliveryInfo.length < 2)
        ) {
            validationResult = validationResult.addPropertyError(
                "deliveryInfo",
                "webBooking.contact.validation.deliveryInfo"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.contact.validation.deliveryInfo"
            );
        }

        if (
            this.isRequired("customerName", mandatoryWebBookingContactFields) &&
            (this.customerName == null || this.customerName.length < 2)
        ) {
            validationResult = validationResult.addPropertyError(
                "customerName",
                "customer.validation.name"
            );
            validationResult = validationResult.addGeneralError("customer.validation.name");
        } else if (this.customerName.length > 50) {
            validationResult = validationResult.addPropertyError(
                "customerName",
                "customer.validation.customerNameExceeded"
            );
            validationResult = validationResult.addGeneralError(
                "customer.validation.customerNameExceeded"
            );
        }

        if (
            this.isRequired("organizationCode", mandatoryWebBookingContactFields) &&
            (this.organizationCode == null ||
                Validators.organizationCode(this.organizationCode) !== null)
        ) {
            validationResult = validationResult.addPropertyError(
                "organizationCode",
                "webBooking.customer.validation.organizationCode"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.customer.validation.organizationCode"
            );
        }

        if (
            this.isRequired("invoiceStreetAddress", mandatoryWebBookingContactFields) &&
            (this.invoiceStreetAddress == null || this.invoiceStreetAddress === "")
        ) {
            validationResult = validationResult.addPropertyError(
                "invoiceStreetAddress",
                "webBooking.customer.validation.invoiceStreetAddress"
            );
            validationResult = validationResult.addGeneralError(
                "webBooking.customer.validation.invoiceStreetAddress"
            );
        }

        if (
            this.isRequired("invoiceEmail", mandatoryWebBookingContactFields) &&
            (this.invoiceEmail == null || Validators.email(this.invoiceEmail) !== null)
        ) {
            validationResult = validationResult.addPropertyError(
                "invoiceEmail",
                "customer.validation.email"
            );
            validationResult = validationResult.addGeneralError("customer.validation.email");
        }

        return validationResult;
    }

    private validateWarnings(validationResult: ValidationResult): ValidationResult {
        return validationResult;
    }

    private isRequired(
        fieldName: keyof IMandatoryWebBookingContactFields,
        mandatoryWebBookingContactFields: MandatoryWebBookingContactFields = new MandatoryWebBookingContactFields()
    ): boolean {
        return mandatoryWebBookingContactFields && mandatoryWebBookingContactFields.has(fieldName)
            ? mandatoryWebBookingContactFields.get(fieldName)
            : false;
    }
}
