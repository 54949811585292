import { Box, Stack, Text, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import Price from "components/price";
import { useWebBooking } from "hooks";
import { useSelectedRule } from "hooks/use-selected-rule";
import { t } from "i18next";
import ChargeStatusEnum from "old/models/chargeStatusEnum";
import { isWaitList } from "utils/booking";
import { getPaymentCalculationTotal, getPaymentUnitTranslation } from "utils/payment-utils";
import { BookingSummaryItem } from "./booking-summary-list-item";

const CardRegistrationInformation = () => {
    const { data: webBooking } = useWebBooking();
    const selectedRule = useSelectedRule();

    const isWaitListBooking = isWaitList(webBooking);

    let totalValue = getPaymentCalculationTotal(
        selectedRule,
        webBooking.guests,
        webBooking?.articles?.toArray().filter((x) => x.isTable)
    );

    const paymentType = webBooking.webTimeRules?.first()?.didShowUpRuleType;
    if (!paymentType) totalValue = webBooking?.chargeShouldPayAmount;

    return (
        <Box>
            <Text fontWeight="medium">{t("payment.paymentCardRegistration")}</Text>
            <Stack spacing={ThemeSpaceVariable.XSmall} mt={ThemeSpaceVariable.Small}>
                <BookingSummaryItem
                    label={t("payment.paymentCardRegistered")}
                    value={
                        webBooking.chargeStatus === ChargeStatusEnum.CardRegistered
                            ? t("yes")
                            : t("no")
                    }
                />
                {!isWaitListBooking && (
                    <BookingSummaryItem label={t("payment.noShowFee")}>
                        <Price
                            amount={totalValue}
                            display="symbol"
                            color={ThemeColorVariable.OnSurfaceSubdued}
                        />
                    </BookingSummaryItem>
                )}
                {!isWaitListBooking && paymentType && (
                    <BookingSummaryItem
                        label=""
                        value={
                            <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                                (
                                <Price
                                    amount={selectedRule.paymentAmount}
                                    display="symbol"
                                    color={ThemeColorVariable.OnSurfaceSubdued}
                                />{" "}
                                / {getPaymentUnitTranslation(selectedRule)})
                            </Text>
                        }
                    />
                )}
            </Stack>
        </Box>
    );
};

export default CardRegistrationInformation;
