import { List, Record } from "immutable";
import moment from "moment-timezone";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";
import { BookingMenuExternalGroup } from "old/models/bookingMenuExternalGroup";
import GroupType from "old/models/bookingMenuExternalGroupTypeEnum";
import BookingMenuStatusEnum from "old/models/bookingMenuExternalStatusEnum";
import ChargeTypeEnum from "old/models/chargeTypeEnum";

export interface IBookingMenuExternal {
    publicId: string;
    name: string;
    groups: List<BookingMenuExternalGroup>;
    status: BookingMenuStatusEnum;
    guests: number; //comes from parent booking, if any. Used for validation
    message: string;
    sent: IMoment;
    chargeType: ChargeTypeEnum;
}

const BookingMenuExternalRecord = RecordFactory<IBookingMenuExternal>({
    publicId: "",
    name: "",
    groups: List<BookingMenuExternalGroup>(),
    status: BookingMenuStatusEnum.TemplateOrUnknown,
    guests: -1,
    message: "",
    sent: null,
    chargeType: ChargeTypeEnum.None,
});

export class BookingMenuExternal extends BookingMenuExternalRecord implements IBookingMenuExternal {
    constructor(values?: Partial<IBookingMenuExternal>) {
        if (values && !(values instanceof Record)) {
            if (values.groups && !(values.groups instanceof List)) {
                values.groups.forEach((g: any) => (g.guests = values.guests)); //pass on guests to groups, used for validation
                values.groups = List<BookingMenuExternalGroup>(
                    values.groups.map((g: any) => new BookingMenuExternalGroup(g))
                );
            }

            if (values.sent) {
                values.sent = moment(values.sent, "YYYY-MM-DDTHH:mm:ss");
            }
        }

        super(values);
    }

    isAnyQuantitySelectionMade(): boolean {
        let selectionFound: boolean = false;

        this.groups.forEach((group) => {
            group.items.forEach((item) => {
                if (item.quantity > 0) {
                    selectionFound = true;
                }
            });
        });

        return selectionFound;
    }

    validate(_errors: boolean = true, _warnings: boolean = true): ValidationResult {
        let validationResult = new ValidationResult();

        if (
            this.status !== BookingMenuStatusEnum.Sent ||
            this.groups.every((g) => g.groupType === GroupType.ViewOnly)
        ) {
            return validationResult;
        } //Only validate menus that are not confirmed or template

        this.groups.forEach(
            (g) => (validationResult = validationResult.mergeValidationResult(g.validate()))
        );

        let quantities = 0;

        this.groups.forEach((g) => {
            if (g.groupType !== GroupType.ViewOnly) {
                quantities += g.sumOfItemQuantities();
            }
        });

        //BB-883 - should not be possible to respond with only a message. Need quantities
        //var hasMessage = menu.message!=null && menu.message.length > 0;
        if (quantities < 1) {
            //&& !hasMessage) {
            validationResult = validationResult.addGeneralError(
                "webBooking.bookingMenu.validation.nothingEntered"
            );
        }

        return validationResult;
    }

    public static sumOfAmount(menus: List<IBookingMenuExternal>): number {
        return menus
            ? menus
                  .map((menu) =>
                      menu.groups
                          ? menu.groups
                                .map((g) => g.sumOfAmount())
                                .reduce(
                                    (previousValue, currentValue) =>
                                        <number>previousValue + <number>currentValue
                                )
                          : 0
                  )
                  .reduce(
                      (previousValue2, currentValue2) =>
                          <number>previousValue2 + <number>currentValue2,
                      0
                  )
            : 0;
    }
}
