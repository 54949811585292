import GroupType from "old/models/bookingMenuExternalGroupTypeEnum";
import { BookingMenuExternalItem } from "old/models/bookingMenuExternalItem";
import BookingMenuExternalItemWebFlagsEnum from "old/models/bookingMenuExternalItemWebFlagsEnum";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";
import { List, Record } from "immutable";

export interface IBookingMenuExternalGroup {
    groupType: GroupType;
    name: string;
    choices: number;
    items: List<BookingMenuExternalItem>;
    guests: number;
    optional: boolean;
    isCollapsed: boolean;
}

const BookingMenuExternalGroupRecord = RecordFactory<IBookingMenuExternalGroup>({
    groupType: null,
    name: "",
    choices: 0,
    items: List<BookingMenuExternalItem>(),
    guests: -1,
    optional: false,
    isCollapsed: false,
});

export class BookingMenuExternalGroup
    extends BookingMenuExternalGroupRecord
    implements IBookingMenuExternalGroup
{
    constructor(values?: Partial<IBookingMenuExternalGroup>) {
        if (values && !(values instanceof Record)) {
            if (values.items) {
                values.items = List<BookingMenuExternalItem>(
                    values.items.map((i: any) => new BookingMenuExternalItem(i))
                );
            }
        }

        super(values);
    }

    validateErrors(validationResult: ValidationResult): ValidationResult {
        //sum all item quantities
        const itemCount = (<List<number>>this.items.map((i) => i.quantity)).reduce(
            (previousValue, currentValue) => <number>previousValue + <number>currentValue
        );
        const distinctChoicesInGroup = this.items.filter((i) => i.quantity > 0).count();
        switch (this.groupType) {
            case GroupType.ViewOnly:
                // if (itemCount > 0) {
                //     validationResult=validationResult.addPropertyError('quantity', "");
                //     validationResult=validationResult.addGeneralError('booking.bookingMenu.validation.hasQuantity');
                // }
                break;
            case GroupType.SingleChoice:
            case GroupType.MultipleChoices:
                if (!this.optional && distinctChoicesInGroup === 0) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.obligatoryChoices"
                    );
                }
                break;
            case GroupType.AmountChoice:
                //no validation??
                break;
            case GroupType.LimitedChoices:
                if (distinctChoicesInGroup > this.choices) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.greaterThanLimitedChoices"
                    );
                }
                break;
            case GroupType.LimitedMinChoicesToGuests:
                if (itemCount < this.guests) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.lessThanGuests",
                        { guests: this.guests }
                    );
                }
                break;
            case GroupType.LimitedMinMaxChoicesToGuests:
                if (itemCount < this.guests) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.lessThanGuests",
                        { guests: this.guests }
                    );
                }
                if (distinctChoicesInGroup > this.choices) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.greaterThanLimitedChoices"
                    );
                }
                break;
            case GroupType.LimitedToGuests:
                if (itemCount !== this.guests) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.exactlyTheSameAsGuests",
                        { guests: this.guests }
                    );
                }
                break;
            case GroupType.LimitedToGuestsAndChoices:
                if (itemCount !== this.guests) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.exactlyTheSameAsGuests",
                        { guests: this.guests }
                    );
                }
                if (distinctChoicesInGroup > this.choices) {
                    validationResult = validationResult.addPropertyError("quantity", "");
                    validationResult = validationResult.addGeneralError(
                        "webBooking.bookingMenu.validation.greaterThanLimitedChoices"
                    );
                }
                break;
            default:
                return validationResult;
        }

        return validationResult;
    }

    validateWarnings(validationResult: ValidationResult): ValidationResult {
        this.items.map((item) => {
            if (item.wasOutOfStock)
                validationResult = validationResult.addGeneralWarning(
                    "webBooking.bookingMenu.validation.wasOutOfStock",
                    { itemName: item.name }
                );
        });
        return validationResult;
    }

    validate(errors: boolean = true, warnings: boolean = true): ValidationResult {
        let validationResult = new ValidationResult();

        if (errors) {
            validationResult = this.validateErrors(validationResult);
        }

        if (warnings) {
            validationResult = this.validateWarnings(validationResult);
        }

        return validationResult;
    }

    sumOfItemQuantities(): number {
        return this.items
            .filter((i) => i.webFlags !== BookingMenuExternalItemWebFlagsEnum.Hide)
            .map((i) => i.quantity)
            .reduce((previousValue, currentValue) => previousValue + <number>currentValue, 0);
    }

    sumOfAmount(): number {
        return this.items
            .filter((i) => i.webFlags !== BookingMenuExternalItemWebFlagsEnum.Hide)
            .map((i) => i.quantity * i.price)
            .reduce((previousValue, currentValue) => previousValue + <number>currentValue, 0);
    }
}
