enum WebBookingStepsEnum {
    Undefined = 0,
    BookingCanceled = 1,
    Start = 100,
    ContactInformation = 200,
    Condition = 300,
    BookingSummary = 350,
    Menu = 600,
    NoMenu = 625,
    MenuSummary = 650,
    MenuFinal = 660,
    MenuAlreadyConfirmed = 680,
    PaymentTerminal = 800,
    PaymentTerminalReturn = 810,
    PaymentError = 820,
    Final = 400,
    FinalError = 500,
    BookingCancel = 450, // skillnad mellan BookingCanceled ?
    BookingCancelSuccess = 455, // vilken vy är denna ?
    Error = 700,
    LinkError = 710, // Klar
    PostError = 730, // Klar vynconfirm?
    DoubleBookingError = 740, //Klar vynconfirm?
    FullyBooked = 750,
}

export default WebBookingStepsEnum;
